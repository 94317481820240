
/**
 * 强类型限制, 增加属性必须加上类型限制以及readonly
 * 如果没有显式定义类型, 则其他文件引用后无法提示语法
 */
type OSSAPIS = {
	readonly OSS_POLICY_SMS_SEND_TRIGGER: string;
	readonly OSS_POLICY_SMS_VALIDATE_RESULT: string;
	readonly OSS_POLICY_POLICY: string;
	// readonly OSS_POLICY_AUTH_NAME: string;
	readonly OSS_POLICY_QUOTE_TRIGGER: string;
	readonly OSS_POLICY_APPLY_TRIGGER: string;
	readonly OSS_ACCOUNT_ES_TRIGGER: string;
	readonly OSS_ACCOUNT_CHANGE_PHONE: string;
	// readonly OSS_POLICY_APPLY: string;
	readonly OSS_AGENT_RESULT: string;
	readonly OSS_EKANGJI_CONFIG: string;

};
/**
 * 所有API路由声明
 */
export default {
	OSS_POLICY_SMS_SEND_TRIGGER: `:tenantCode/:productCode/SMS/:date/:mobile/sms_send_trigger`,
	OSS_POLICY_SMS_VALIDATE_RESULT: `:tenantCode/:productCode/SMS/:date/:mobile/:validateCode_result.json`,
	OSS_POLICY_POLICY: `:tenantCode/:productCode/POLICY/:quoteNo/policy.json`,
	// OSS_POLICY_AUTH_NAME: `auth/:tenantCode/:date/:productCode/:idNo/:quoteNo/real_name_auth_:time.json`,
	OSS_POLICY_QUOTE_TRIGGER: `:tenantCode/:productCode/POLICY/:quoteNo/quote_trigger.json`,
	OSS_POLICY_APPLY_TRIGGER: `:tenantCode/:productCode/POLICY/:quoteNo/apply_trigger.json`,
	OSS_ACCOUNT_ES_TRIGGER: `:tenantCode/:productCode/ACCOUNT/:accountName/account_es_trigger.json`,
	OSS_ACCOUNT_CHANGE_PHONE: `:tenantCode/:productCode/ACCOUNT/:accountName/account_change_phone.json`,
	// OSS_POLICY_APPLY: `:tenantCode/:productCode/:quoteNo/apply.json`,
	OSS_AGENT_RESULT:`:tenantCode/AGENT/:agentCode.json`,
	OSS_EKANGJI_CONFIG: 'EKANGJI/CONFIG/EKANGJICONFIG.json',



} as OSSAPIS;
