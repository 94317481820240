import { Ajax, Apis, Envs, Storage } from '@common';
import { AjaxResponse } from '@my-types';

enum ShareMethod {
	MESSAGE = 'onMenuShareAppMessage',
	TIMELINE = 'onMenuShareTimeline',
	QQ = 'onMenuShareQQ',
	WEIBO = 'onMenuShareWeibo'
}
type ShareData = {
	title: string;
	desc: string;
	link: string;
	imgUrl: string;
};
class Wechat {
	private static isEnabled() {
		return Envs.isWechat();
	}

	static closeWindow(): void {
        if (!this.isEnabled()) {
            return;
        }
        import(/* webpackChunkName: "wechat-sdk" */ 'wechat-js-sdk-s').then(data => {
            const wx = data.default;
            //关闭微信窗口
            wx.closeWindow();
        });
	}

	static shieldingMenuItems(): void {
		if (!this.isEnabled()) {
			return;
		}
		import(/* webpackChunkName: "wechat-sdk" */ 'wechat-js-sdk-s').then(data => {
			const wx = data.default;
			Ajax.post(Apis.WECHAT_SIGNATURE, {
				// FIXME 微信iphone url有注册问题, 必须使用进入的第一个url注册
				url: Envs.isIphone() && Envs.isWechat() ? Storage.Share.getFirstUrl() : window.location.href
			}).then((response: AjaxResponse) => {
				const data = (response.body || {}).body;
				if (data) {
					wx.config({
						debug: false,
						appId: data.appid,
						timestamp: data.timestamp,
						nonceStr: data.nonceStr,
						signature: data.signature,
						jsApiList: [
							'hideMenuItems'
						]
					});

					wx.ready(function () {
						wx.hideMenuItems({
							menuList: [
								'menuItem:share:appMessage',    //发送给朋友
								'menuItem:share:timeline',    //分享到朋友圈
								'menuItem:share:qq', //QQ
								'menuItem:share:weiboApp', //微博
								'menuItem:share:QZone',
								'menuItem:share:facebook',
								'menuItem:favorite',
								'menuItem:originPage',
								'menuItem:openWithQQBrowser',
								'menuItem:openWithSafari',
								'menuItem:share:email',
								'menuItem:share:brand',
								'menuItem:copyUrl'    //复制链接
							]
						});

					});

					wx.error((res: any) => {
						// alert(JSON.stringify(res));
						console.error(res);
					});
				} else {
					console.error('获取微信配置信息失败');
				}
			});
		});

	}

	/**
	 * 微信下载图片的接口
	 * @param anchorElement
	 */
    static imageDowloadUseWechart( anchorElement:HTMLAnchorElement):void{
        import(/* webpackChunkName: "wechat-sdk" */ 'wechat-js-sdk-s').then(data => {
            //配置
            const wx = data.default;
            console.log("const wx = data.default中data.default:",data.default);
            wx.config({
                debug: false,
                //appId: data.appid,
                appId: "wx15874b8aa32ae197",
                timestamp: data.timestamp,
                nonceStr: data.nonceStr,
                signature: data.signature,
                jsApiList: [
                    'updateAppMessageShareData',
                    "onMenuShareAppMessage",
                    "chooseImge",
                    "uploadImage",
                    "downloadImage"
                ]
            });
            wx.chooseImage({
                count:1,
                sizeType:['original','compressed'],
                sourceType:['album','camera'],
                success:function (res:any) {
                    var loaclIds=res.localIds;
                    wx.uploadImage({
                        localId:loaclIds,
                        isShowProgressTips:1,
                        success:function (res:any) {
                            var serverId=res.serverId;
                            wx.downloadImage({
                                serverId:serverId,
                                isShowProgressTips:1,
                                success:function (res:any) {
                                    var localId=res.localId;
                                    wx.getLocalImgData({
                                        localId:localId,
                                        success:function (res:any) {
                                            var localData = res.localData;
                                            anchorElement.href=localData;
                                            document.body.appendChild(anchorElement);
                                            anchorElement.click();
                                            document.body.removeChild(anchorElement);
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            });


        })
    }



	/**
     * 自定义分享给朋友的接口
     */
	static shareFriends():void{
		import(/* webpackChunkName: "wechat-sdk" */ 'wechat-js-sdk-s').then(data => {
			//配置
			const wx = data.default;
			console.log("const wx = data.default中data.default:",data.default);
			wx.config({
				debug: false,
				//appId: data.appid,
				appId: "wx15874b8aa32ae197",
				timestamp: data.timestamp,
				nonceStr: data.nonceStr,
				signature: data.signature,
				jsApiList: [
					'updateAppMessageShareData',
					"onMenuShareAppMessage"
				]
			});
			wx.ready(function () {
				console.log("加载完成");
			});
			wx.error(function (res:any) {
				console.log("失败了，失败的数据是:",res);
			});

			//分享
			wx.ready(function () {
                wx.onMenuShareAppMessage({
                    title:"这是测试",
                    desc:"还是测试",
                    link:"https://ekangji-tst.ebaocloud.com.cn/csms2/agent/poster/show/CHINALIFE/HNAYPHJKB/003",
                    success:function () {
                        console.log("分享成功2222222");
                    }
                });
			});
            wx.onMenuShareAppMessage({
                title:"这是测试",
                desc:"还是测试",
                link:"https://ekangji-tst.ebaocloud.com.cn/csms2/agent/poster/show/CHINALIFE/HNAYPHJKB/003",
                success:function () {
                    console.log("分享成功2222222");
                }
            });


		})
	}
	static initialize(shareData: ShareData): void {
		console.info('Share to: ', shareData.link);
		if (!this.isEnabled()) {
			return;
		}
		// webasset URL处理，图片URL中未包含https，则补充协议和域名
        if (shareData.imgUrl && shareData.imgUrl.indexOf('https:') < 0) {
            shareData.imgUrl = 'https://' + window.location.host + shareData.imgUrl;
        }

		import(/* webpackChunkName: "wechat-sdk" */ 'wechat-js-sdk-s').then(data => {
			const wx = data.default;
			//判断是否是微信小程序环境
			if (Envs.isWechatMiniProgram()) {
				//向小程序发送信息
				wx.miniProgram.postMessage({
					data: {
						action: 'share',
						title: shareData.title,
						desc: shareData.desc,
						shareUrl: shareData.link
					}
				});
			}

			const share = Wechat.createShare(wx);
			Ajax.post(Apis.WECHAT_SIGNATURE, {
				// FIXME 微信iphone url有注册问题, 必须使用进入的第一个url注册
				url: Envs.isIphone() && Envs.isWechat() ? Storage.Share.getFirstUrl() : window.location.href
			}).then((response: AjaxResponse) => {
				const data = (response.body || {}).body;
				if (data) {
					wx.config({
						debug: false,
						appId: data.appid,
						timestamp: data.timestamp,
						nonceStr: data.nonceStr,
						signature: data.signature,
						jsApiList: [
							//'checkJsApi',
							'onMenuShareTimeline',
							'onMenuShareAppMessage'
							// 'onMenuShareQQ',
							// 'onMenuShareWeibo'
							//'hideMenuItems'
						]
					});

					wx.ready(function () {
						//1 监听“分享给好友”按钮点击、自定义分享内容及分享结果接口
						share(shareData, ShareMethod.MESSAGE, 1000);
						// 2 监听“分享到朋友圈”按钮点击、自定义分享内容及分享结果接口
						share(shareData, ShareMethod.TIMELINE, 1000);
						// share(shareData, 'onMenuShareQQ', 4000);
						// share(shareData, 'onMenuShareWeibo', 5000);
					});

					wx.error((res: any) => {
						console.error(res);
					});
				} else {
					console.error('获取微信配置信息失败');
				}
			});
		});
	}
	/**
	 * 创建share监听器
	 */
	private static createShare(wx: any): (data: any, method: ShareMethod, delay: number) => void {
		return (data: any, method: ShareMethod, delay: number = 0) => {
			const link = document.createElement('a');
			switch (method) {
				case ShareMethod.MESSAGE:
					link.onclick = () => {
						wx.onMenuShareAppMessage(data);
					};
					break;
				case ShareMethod.TIMELINE:
					link.onclick = () => {
						wx.onMenuShareTimeline(data);
					};
					break;
				case ShareMethod.QQ:
					link.onclick = () => {
						wx.onMenuShareQQ(data);
					};
					break;
				case ShareMethod.WEIBO:
					link.onclick = () => {
						wx.onMenuShareWeibo(data);
					};
					break;
				default:
					return;
			}
			setTimeout(() => {
				link.click();
				link.remove();
			}, delay);
		};
	}
}

export default Wechat;
