/**
 * 强类型限制, 增加属性必须加上类型限制以及readonly
 * 如果没有显式定义类型, 则其他文件引用后无法提示语法
 */
type PATHS = {
	readonly DESK_SIGN: string;
	readonly DESK_SIGN_IN: string;
	readonly DESK_SIGN_UP: string;

	readonly DESK_HOME: string;
	readonly DESK_NOT_FOUND: string;

	readonly DESK_TENANT: string;
	readonly DESK_TENANT_VIEW: string;
	readonly DESK_TENANT_CURRENT: string;
	readonly DESK_TENANT_CREATE: string;
	readonly DESK_TENANT_EDIT: string;
	readonly DESK_TENANT_MENUS_AUTH: string;
	readonly DESK_TENANT_LIST: string;
	readonly DESK_TENANT_API_KEY: string;

	readonly DESK_ORGAN: string;
	readonly DESK_ORGAN_VIEW: string;
	readonly DESK_ORGAN_CURRENT: string;
	readonly DESK_ORGAN_CREATE: string;
	readonly DESK_ORGAN_EDIT: string;
	readonly DESK_ORGAN_LIST: string;
	readonly DESK_ORGAN_UPLOAD_SYNC: string;

	readonly DESK_SERVICE: string;
	readonly DESK_SERVICE_LIST_VIEW: string;
	readonly DESK_SERVICE_LIST_VIEW_VIEW: string;
	readonly DESK_SERVICE_PROBATION_LIST: string;
	readonly DESK_SERVICE_PROBATION_EDIT: string;
	readonly DESK_SERVICE_PROBATION_VIEW: string;

	readonly DESK_ACCOUNT: string;
	readonly DESK_ACCOUNT_CURRENT: string;
	readonly DESK_ACCOUNT_CREATE: string;
	readonly DESK_ACCOUNT_EDIT: string;
	readonly DESK_ACCOUNT_LIST: string;
	readonly DESK_ACCOUNT_CHANGE_PWD: string;
	readonly DESK_ACCOUNT_UPLOAD_SYNC: string;

	readonly DESK_ACTUATOR: string;
	readonly DESK_ACTUATOR_HEALTH: string;
	readonly DESK_ACTUATOR_BEANS: string;
	readonly DESK_ACTUATOR_ENV: string;
	readonly DESK_ACTUATOR_METRICS: string;
	readonly DESK_ACTUATOR_LOGGERS: string;
	readonly DESK_ACTUATOR_MAPPINGS: string;
	readonly DESK_ACTUATOR_CONFIGPROPS: string;
	readonly DESK_ACTUATOR_LOGFILE: string;
	readonly DESK_ACTUATOR_LOGLEVEL: string;

	readonly DESK_TENANT_REGISTER: string;
	readonly DESK_TENANT_REGISTER_APPLY: string;
	readonly DESK_TENANT_REGISTER_LIST: string;
	readonly DESK_TENANT_REGISTER_APPROVE: string;

	readonly DESK_PRODUCT: string;
	readonly DESK_PRODUCT_LIST: string;
	readonly DESK_PRODUCT_CREATE: string;
	readonly DESK_PRODUCT_EDIT: string;
	readonly DESK_PRODUCT_VIEW: string;

	readonly DESK_PRODUCT_HOT: string;
	readonly DESK_PRODUCT_HOT_LIST: string;
	readonly DESK_PRODUCT_HOT_EDIT: string;

	readonly DESK_PRODUCT_UI: string;
	readonly DESK_PRODUCT_UI_LIST: string;
	readonly DESK_PRODUCT_UI_CREATE: string;
	readonly DESK_PRODUCT_UI_EDIT: string;

	readonly DESK_PRODUCT_TAG: string;
	readonly DESK_PRODUCT_TAG_LIST: string;
	readonly DESK_PRODUCT_TAG_CREATE: string;
	readonly DESK_PRODUCT_TAG_EDIT: string;

	readonly DESK_PRODUCT_TAG_JOINT: string;
	readonly DESK_PRODUCT_TAG_JOINT_LIST: string;
	readonly DESK_PRODUCT_TAG_JOINT_EDIT: string;

	readonly DESK_PRODUCT_EXPLORE: string;
	readonly DESK_PRODUCT_EXPLORE_LIST: string;
	readonly DESK_PRODUCT_EXPLORE_EDIT: string;
	readonly DESK_PRODUCT_EXPLORE_CREATE: string;

	readonly DESK_AGREEMENT: string;
	readonly DESK_AGREEMENT_LIST: string;
	readonly DESK_AGREEMENT_CONSUMER_LIST: string;
	readonly DESK_AGREEMENT_VENDOR_LIST: string;
	readonly DESK_AGREEMENT_CREATE: string;
	readonly DESK_AGREEMENT_EDIT: string;
	readonly DESK_AGREEMENT_CLONE: string;
	readonly DESK_AGREEMENT_CONSUMER_EDIT: string;
	readonly DESK_AGREEMENT_VENDOR_EDIT: string;

	readonly DESK_COMMISSION: string;
	readonly DESK_COMMISSION_LIST: string;
	readonly DESK_COMMISSION_CREATE: string;
	readonly DESK_COMMISSION_EDIT: string;

	readonly DESK_MESSAGE: string;
	readonly DESK_MESSAGE_LIST: string;
	readonly DESK_MESSAGE_CREATE: string;
	readonly DESK_MESSAGE_EDIT: string;

	readonly DESK_POLICY: string;
	readonly DESK_POLICY_LIST: string;
	readonly DESK_POLICY_URL_LIST: string;
	readonly DESK_POLICY_OSS_URL: string;
	readonly DESK_ADVISE_LIST: string;
	readonly DESK_POLICY_CREATE: string;
	readonly DESK_POLICY_VIEW: string;
	readonly DESK_STATISTICS: string;
	readonly DESK_POLICY_DOWNLOAD: string;
	readonly DESK_SERVICE_FEE_DOWNLOAD: string;

	readonly DESK_IDENTIRY_CERTIFICATION: string;

	readonly DESK_POLICY_NOTIFY_LOG: string;
	readonly DESK_POLICY_NOTIFY_LOG_LIST: string;
	readonly DESK_POLICY_NOTIFY_LOG_VIEW: string;
	readonly DESK_POLICY_NOTIFY_LOG_POLICY_VIEW: string;

	readonly DESK_POLICY_PROBLEM: string;

	readonly DESK_STATEMENT: string;
	readonly DESK_STATEMENT_AGREEMENT: string;
	readonly DESK_STATEMENT_AGREEMENT_LIST: string;
	readonly DESK_STATEMENT_AGREEMENT_CREATE: string;
	readonly DESK_STATEMENT_AGREEMENT_EDIT: string;
	readonly DESK_STATEMENT_AGREEMENT_VIEW: string;
	readonly DESK_STATEMENT_COMMISSION: string;
	readonly DESK_STATEMENT_COMMISSION_MAINTAIN: string;
	readonly DESK_STATEMENT_BILL: string;
	readonly DESK_STATEMENT_BILL_MAINTAIN: string;
	readonly DESK_STATEMENT_BILL_RESULT_LIST: string;


	readonly DESK_CUSTOMER: string;
	readonly DESK_CUSTOMER_LIST: string;
	readonly DESK_CUSTOMER_VIEW: string;

	readonly DESK_ASYNC_POLICY: string;

	readonly DESK_DASHBOARD: string;

	readonly DESK_SMS: string;
	readonly DESK_SMS_BLACK_LIST: string;
	readonly DESK_AGENT_SYNC_QUERY: string;

	readonly DESK_GROUP_POLICY: string;
	readonly DESK_GROUP_POLICY_LIST: string;
	readonly DESK_GROUP_POLICY_VIEW: string;
	readonly DESK_GROUP_POLICY_CREATE: string;

	readonly DESK_TRAINING: string;
	readonly DESK_COURSE: string;
	readonly DESK_COURSE_LIST: string;
	readonly DESK_COURSE_CREATE: string;
	readonly DESK_COURSE_EDIT: string;
	readonly DESK_QUIZ_LIST: string;
	readonly DESK_QUIZ_CREATE: string;
	readonly DESK_QUIZ_EDIT: string;
	readonly DESK_ACCOUNT_QUIZ_LIST: string;
	readonly DESK_ACCOUNT_COURSE_LIST: string;

	readonly DESK_AUDIT_LOG: string;
	readonly DESK_AUDIT_LOG_LIST: string;

	readonly AGENT_SIGN: string;
	readonly AGENT_SIGN_IN: string;
	readonly AGENT_PERSONINFO_SETTING: string;
	readonly AGENT_SETTING: string;
	readonly AGENT_PHONECHANGE: string;
	readonly AGENT_ACCOUNTPASS_CHANGE: string;
	readonly AGENT_MYPOLICY: string;
	readonly AGENT_MYPOLICY_ALL: string;
	readonly AGENT_MYPOLICY_ISSUED: string;
	readonly AGENT_MYPOLICY_WAIT_PAID: string;
	readonly AGENT_MYPOLICY_WAIT_COMPLETE: string;
	readonly AGENT_MYPOLICY_INVALID: string;
	readonly AGENT_BIND_MOBILE: string;
	readonly AGENT_HOME: string;
	readonly AGENT_HB: string;
	readonly AGENT_ME: string;
	readonly AGENT_ME_MY_POLICY: string;
	readonly AGENT_INDEX: string;
	readonly AGENT_REGISTRY: string;

	readonly MOBILE_SIGN: string;
	readonly MOBILE_SIGN_IN: string;
	readonly MOBILE_SIGN_UP: string;
	readonly MOBILE_SIGN_BJHL: string;

	readonly MOBILE_HOME: string;
	readonly MOBILE_CATEGORY: string;
	readonly MOBILE_EXPLORE: string;

	readonly MOBILE_PRODUCT_SEARCH_BY_KEYWORD: string;
	readonly MOBILE_PRODUCT_SEARCH_BY_3RD_TAGS: string;
	readonly MOBILE_PRODUCT_SEARCH_BY_VENDORS: string;
	readonly MOBILE_PRODUCT_SEARCH_BY_ADVICE: string;

	readonly MOBILE_POLICY_ADVICE: string;
	readonly MOBILE_POLICY_QUOTE: string;
	readonly MOBILE_POLICY_QUOTE_DIRECT: string;
	readonly MOBILE_POLICY_INFO: string;
	readonly MOBILE_POLICY_INFO_READONLY: string;
	readonly MOBILE_POLICY_INFO_SHAREONLY: string;
	readonly MOBILE_POLICY_INFO_UNLOGIN: string;
	readonly MOBILE_POLICY_INFO_SHOW: string;
	readonly MOBILE_POLICY_VIEW_BYQUOTE: string;
	readonly MOBILE_POLICY_VIEW_BYQUOTE_LVJIAN: string;
	readonly MOBILE_POLICY_VIEW_BYQUOTE_HMB: string;
	readonly MOBILE_POLICY_PICCGI_HNHBHHB2022_INFO: string;
	readonly MOBILE_POLICY_VIEW_BYOSSQUOTE_PICCGI_HNHBHHB2022: string;
	readonly MOBILE_POLICY_VIEW_BYOSSQUOTE_PICCGI_GXYLYHB: string;
	readonly MOBILE_POLICY_VIEW_READONLY_BYQUOTE: string;
	readonly MOBILE_POLICY_ADVICE_READONLY: string;
	readonly MOBILE_POLICY_ADVICE_TO_QUOTE: string;
	readonly MOBILE_POLICY_ADVICE_TO_QUOTE_NO_ID: string;
	readonly MOBILE_POLICY_ADVICE_DIRECT: string;
	readonly MOBILE_POLICY_APPLY_PENDING: string;
	readonly MOBILE_POLICY_APPLY_SUCCESS: string;
	readonly MOBILE_POLICY_INSURED_CALLBACK: string;
	readonly MOBILE_POLICY_HOLDER_CALLBACK: string;
	readonly MOBILE_POLICY_PAY_SUCCESS: string;
	readonly MOBILE_POLICY_PAY_TRADING: string;
	readonly MOBILE_POLICY_APPLY_FAIL: string;
	readonly MOBILE_POLICY_WECHAT_MINI_TRANSITION: string;
	readonly MOBILE_POLICY_SIGNATURE: string;

	readonly MOBILE_ME: string;
	readonly MOBILE_ME_MY_POLICY: string;
	readonly MOBILE_ME_MY_QUOTE: string;
	readonly MOBILE_ME_MY_ADVICE: string;
	readonly MOBILE_ME_POLICY_QUERY: string;
	readonly MOBILE_ME_FAV: string;
	readonly MOBILE_ME_CUSTOMER: string;
	readonly MOBILE_ME_CUSTOMER_VIEW: string;
	readonly MOBILE_ME_CUSTOMER_POLICY: string;
	// SUSPEND  此功能暂停开放, 目前无使用租户 20190321 Brad Wu
	// readonly MOBILE_ME_CLAIM_CASE: string;
	// readonly MOBILE_ME_CLAIM_QUERY: string;
	// readonly MOBILE_ME_TRAINING: string;
	readonly MOBILE_ME_DASHBOARD: string;
	readonly MOBILE_ME_CHANGE_THEME: string;
	readonly MOBILE_ME_CHANGE_PWD: string;
	readonly MOBILE_ME_PROFILE: string;
	// SUSPEND  此功能暂停开放, 目前无使用租户 20190321 Brad Wu
	// readonly MOBILE_ME_STORE: string;

	readonly MOBILE_POLICY: string;

	readonly MOBILE_STANDALONE: string;
	readonly MOBILE_STANDALONE_ADVICE: string;
	readonly MOBILE_STANDALONE_QUOTE: string;
	readonly MOBILE_STANDALONE_POLICY: string;
	readonly MOBILE_STANDALONE_ORDER: string;
	readonly MOBILE_STANDALONE_PRODUCTS: string;
	readonly MOBILE_STANDALONE_KEYWORD_PRODUCTS: string;
	readonly MOBILE_STANDALONE_VENDOR_PRODUCTS: string;
	readonly MOBILE_STANDALONE_FIRSTTAG_PRODUCTS: string;
	readonly MOBILE_STANDALONE_QUERY: string;

	readonly DOCS_HOME: string;

	readonly MALL_INDEX: string;
	readonly MALL_HOME: string;
	readonly MALL_HOME_VENDOR: string;
	readonly MALL_ABOUTUS: string;
	readonly MALL_COPYRIGHT: string;
	readonly MALL_PRIVACY: string;
	readonly MALL_DISCLOSURE: string;
	readonly Mall_MERCERSIGNIN: string

	readonly Mall_SIGN_UP: string;
	readonly Mall_SIGN_UP_VENDOR: string;
	readonly Mall_FORGET_PASSWORD: string
	readonly Mall_COOPERATIVE: string;
	readonly Mall_PRODUCTINFO: string;
	readonly Mall_PERMITS: string;
	readonly Mall_BUSINESS: string;
	readonly Mall_BUSINESSLICENSE: string;
	readonly Mall_LONGTERMCLAUSE: string;
	readonly Mall_LONGTERMRATE: string;
	readonly Mall_DRUGCLAUSE: string;
	readonly Mall_DRUGRATE: string;
	readonly Mall_PEACECLAUSE: string;
	readonly Mall_PEACERATE: string;
	readonly MALL_PRODUCT: string
	readonly MALL_PRODUCT_VENDOR: string
	readonly MALL_ABOUT_US: string
	readonly MALL_DISCLO_SURE: string
	readonly MALL_HELP: string
	readonly MALL_ABOUT_US_VENDOR: string
	readonly MALL_DISCLO_SURE_VENDOR: string
	readonly MALL_HELP_VENDOR: string
	readonly MALL_BUSSINESS_QUAL: string
	readonly MALL_PRIVACY_POLICY: string
	readonly MALL_TRADE_INFORMATION: string
	readonly MALL_TRADE_SAFETY: string
	readonly MALL_COPYRIGHT_NOTICE: string
	readonly MALL_SETTING: string
	readonly MALL_SETTING_VENDOR: string
	readonly MALL_FAMILY_MEMBER: string;
	readonly MALL_FAMILY_MEMBER_LIST: string;
	readonly MALL_FAMILY_MEMBER_LIST_VENDOR: string;
	readonly MALL_FAMILY_MEMBER_CREATE: string;
	readonly MALL_FAMILY_MEMBER_EDIT: string;
	readonly MALL_MYPOLICY: string;
	readonly MALL_MYPOLICY_ALL: string;
	readonly MALL_MYPOLICY_ISSUED: string;
	readonly MALL_MYPOLICY_WAIT_PAID: string;
	readonly MALL_MYPOLICY_WAIT_COMPLETE: string;
	readonly MALL_MYPOLICY_INVALID: string;
	readonly MALL_MMOREPOLICY: string;
	readonly MALL_PERSONINFO_SETTING: string;
	readonly MALL_PERSONINFO_SETTING_VENDOR: string;
	readonly MALL_PHONECHANGE: string
	readonly MALL_ACCOUNTPASS_CHANGE: string
	readonly MALL_COOPERATION_INSURANCE: string
	readonly MALL_INTERNET_INSURANCE_PRODUCTS: string
	readonly MALL_POLICY_CHECK_TRUE: string
	readonly MALL_PERSONAL_AGREEMENT: string
	readonly MALL_PERSONAL_STATEMENT: string
	readonly MALL_SEARCH_PRODUCT: string
	readonly MALL_SEARCH_PRODUCT_KEY_WORD: string
	readonly MALL_ENTERPRISES_SELECT: string
	readonly MALL_CUSTOMER_SERVICE: string
	readonly MALL_CUSTOMER_SERVICE_VENDOR: string

	readonly MALL_ME: string;
	readonly MALL_ME_VENDOR: string;
	readonly MALL_ME_HOME: string;
	readonly MALL_POLICY: string;
	readonly MALL_KEYWORD_PRODUCTS: string;
	readonly MALL_PROMOTION_PRODUCTS: string;
	readonly MALL_TAG_PRODUCTS: string;
	readonly MALL_MOBILE_SIGN_IN: string;
	readonly MALL_MOBILE_SIGN_IN_VENDOR: string;
	readonly MALL_POLICY_FOR_SHARE: string;
	readonly MALL_BIND_MOBILE: string;
	readonly MALL_TOC: string;
	readonly Mall_TOC_ICON: string;
	readonly MALL_TOC_PERSONALINFO: string;
	readonly MALL_TOC_PERSONALINFO_DETAIL: string;
	readonly MALL_TOC_UNBOUND_MOBILENO: string;
	readonly MALL_TOC_POLICY: string;
	readonly MALL_TOC_ORDER: string;
	readonly MALL_APPOINT: string;
	readonly MALL_JSCN: string;
	readonly MALL_JSCN_ORDER: string;

	readonly INSURANCE_COMPANY: string;
	readonly INSURANCE_COMPANY_BUSSINESS_HQ: string;
	readonly ORDER_CHECK_VIEW: string;
	readonly ORDER_CHECK_APPLY: string;

	readonly MALL_MOBILE_GW_SIGN_IN: string;
	readonly MALL_GW_HOME: string;
	readonly MALL_GW_ME: string;
	readonly MALL_ME_CHANGE_PWD: string;

	readonly MALL_CLAIM: string;
	readonly MALL_CLAIM_APPLICATION: string;
	readonly MALL_CLAIM_GROUP_APPLICATION: string;
	readonly MALL_CLAIM_VIEW: string;

	readonly MOBILE_RECOGNIZE: string;
	readonly MOBILE_RECOGNIZE_DETECT: string;
	readonly MOBILE_RECOGNIZE_DETECT_LIVING: string;
	readonly MOBILE_RECOGNIZE_DETECT_RESULT: string;
	//履践相关
	readonly SURRENDERAPPLICATION:string;
	readonly SURRENDERAPPLICATION_LIST: string;
	readonly SURRENDERAPPLICATION_EDIT: string;
	readonly SURRENDERAPPLICATION_SUCCEED: string;
	readonly JOBCHANGE:string;
	readonly JOBCHANGE_LIST: string;
	readonly JOBCHANGE_EDIT: string;
	readonly JOBCHANGE_SUCCEED: string;
	readonly TURN_NEW_INSURANCE: string;
	readonly TURN_NEW_INSURANCE_QUESTIONNAIRE: string;
	readonly TURN_NEW_INSURANCE_MESSGAE_ENTER: string;
	readonly TURN_NEW_INSURANCE_SUCCEED: string;
	readonly CORRECTING_QUERY: string;
	readonly MALL_CLAIM_APPLY: string;
	readonly MALL_CLAIM_QUERY: string;
	readonly MALL_CLAIM_QUERY_LIST: string;
	readonly MALL_CLAIM_QUERY_VIEW: string;

	//海报相关
	readonly POSTER_POSTER_CREATE: string;
	readonly POSTER_POSTER_SHOW: string;
	//企业信息预约
	readonly AGENT_ENTERPRISE_CREATE: string;
	readonly AGENT_ENTERPRISE_SHOW: string;
	readonly MOBILE_POLICY_CHINALIFE_INFO: string;
	readonly MOBILE_POLICY_PICCGI_GXYLYHB_INFO: string;

	readonly MALL_WECHAT_ACCOUNT_BIND: string;
	readonly MALL_WECHAT_ACCOUNT_BIND_SUCCESS: string;
};

export default {
	DESK_SIGN: '/desk/sign',
	DESK_SIGN_IN: '/desk/sign/in',
	DESK_SIGN_UP: '/desk/sign/up',
	// 桌面
	DESK_HOME: '/desk',
	DESK_NOT_FOUND: '/desk/404',
	// 租户维护
	DESK_TENANT: '/desk/tenant',
	DESK_TENANT_VIEW: '/desk/tenant/view',
	DESK_TENANT_CURRENT: '/desk/tenant/current',
	DESK_TENANT_CREATE: '/desk/tenant/create',
	DESK_TENANT_EDIT: '/desk/tenant/edit/:tenantId',
	DESK_TENANT_MENUS_AUTH: '/desk/tenant/auth/:tenantId',
	DESK_TENANT_LIST: '/desk/tenant/list',
	DESK_TENANT_API_KEY: '/desk/tenant/api',
	DESK_ORGAN: '/desk/organ',
	DESK_ORGAN_VIEW: '/desk/organ/view',
	DESK_ORGAN_CURRENT: '/desk/organ/current',
	DESK_ORGAN_CREATE: '/desk/organ/create',
	DESK_ORGAN_EDIT: '/desk/organ/edit/:organId',
	DESK_ORGAN_LIST: '/desk/organ/list',
	DESK_ORGAN_UPLOAD_SYNC: '/desk/organ/upload-sync',
	DESK_SERVICE: '/desk/service',
	DESK_SERVICE_LIST_VIEW: '/desk/service/list-view/list',
	DESK_SERVICE_LIST_VIEW_VIEW: '/desk/service/list-view/view/:serviceId',
	DESK_SERVICE_PROBATION_LIST: '/desk/service/probation/list',
	DESK_SERVICE_PROBATION_EDIT: '/desk/service/probation/edit/:probationId',
	DESK_SERVICE_PROBATION_VIEW: '/desk/service/probation/view/:probationId',
	DESK_ACCOUNT: '/desk/account',
	DESK_ACCOUNT_CURRENT: '/desk/account/current',
	DESK_ACCOUNT_CREATE: '/desk/account/create',
	DESK_ACCOUNT_EDIT: '/desk/account/edit/:accountId',
	DESK_ACCOUNT_LIST: '/desk/account/list',
	DESK_ACCOUNT_CHANGE_PWD: '/desk/account/change-pwd',
	DESK_ACCOUNT_UPLOAD_SYNC: '/desk/account/upload-sync',
	// 系统健康
	DESK_ACTUATOR: '/desk/actuator',
	DESK_ACTUATOR_HEALTH: '/desk/actuator/health',
	DESK_ACTUATOR_BEANS: '/desk/actuator/beans',
	DESK_ACTUATOR_ENV: '/desk/actuator/env',
	DESK_ACTUATOR_METRICS: '/desk/actuator/metrics',
	DESK_ACTUATOR_LOGGERS: '/desk/actuator/loggers',
	DESK_ACTUATOR_MAPPINGS: '/desk/actuator/mappings',
	DESK_ACTUATOR_CONFIGPROPS: '/desk/actuator/config-props',
	DESK_ACTUATOR_LOGFILE: '/desk/actuator/log-file',
	DESK_ACTUATOR_LOGLEVEL: '/desk/actuator/log-level',
	// 租户注册
	DESK_TENANT_REGISTER: '/desk/tenant-registser',
	DESK_TENANT_REGISTER_APPLY: '/desk/tenant-registser/apply',
	DESK_TENANT_REGISTER_LIST: '/desk/tenant-registser/list',
	DESK_TENANT_REGISTER_APPROVE: '/desk/tenant-registser/approve/:registerId',
	// 产品维护
	DESK_PRODUCT: '/desk/product',
	DESK_PRODUCT_LIST: '/desk/product/list',
	DESK_PRODUCT_CREATE: '/desk/product/create',
	DESK_PRODUCT_EDIT: '/desk/product/edit/:productId',
	DESK_PRODUCT_VIEW: '/desk/product/view/:productId',
	// 产品热销维护
	DESK_PRODUCT_HOT: '/desk/product-hot',
	DESK_PRODUCT_HOT_LIST: '/desk/product-hot/list',
	DESK_PRODUCT_HOT_EDIT: '/desk/product-hot/edit/:productId',
	// 产品UI维护
	DESK_PRODUCT_UI: '/desk/product-ui',
	DESK_PRODUCT_UI_LIST: '/desk/product-ui/list',
	DESK_PRODUCT_UI_CREATE: '/desk/product-ui/create',
	DESK_PRODUCT_UI_EDIT: '/desk/product-ui/edit/:productUIId',
	// 产品标签维护
	DESK_PRODUCT_TAG: '/desk/product-tag',
	DESK_PRODUCT_TAG_LIST: '/desk/product-tag/list',
	DESK_PRODUCT_TAG_CREATE: '/desk/product-tag/create',
	DESK_PRODUCT_TAG_EDIT: '/desk/product-tag/edit/:tagId',
	// 产品标签关联维护
	DESK_PRODUCT_TAG_JOINT: '/desk/product-tag-joint',
	DESK_PRODUCT_TAG_JOINT_LIST: '/desk/product-tag-joint/list',
	DESK_PRODUCT_TAG_JOINT_EDIT: '/desk/product-tag-joint/edit/:productId',
	// 发现维护
	DESK_PRODUCT_EXPLORE: '/desk/product-explore',
	DESK_PRODUCT_EXPLORE_LIST: '/desk/product-explore/list',
	DESK_PRODUCT_EXPLORE_EDIT: '/desk/product-explore/load/:exploreId',
	DESK_PRODUCT_EXPLORE_CREATE: '/desk/product-explore/create',
	// 合约
	DESK_AGREEMENT: '/desk/agreement',
	DESK_AGREEMENT_LIST: '/desk/agreement/list',
	DESK_AGREEMENT_CONSUMER_LIST: '/desk/agreement/consumer/list',
	DESK_AGREEMENT_VENDOR_LIST: '/desk/agreement/vendor/list',
	DESK_AGREEMENT_CREATE: '/desk/agreement/create',
	DESK_AGREEMENT_EDIT: '/desk/agreement/edit/:agreementId',
	DESK_AGREEMENT_CLONE: '/desk/agreement/clone/:agreementId',
	DESK_AGREEMENT_CONSUMER_EDIT: '/desk/agreement/consumer/edit/:agreementId',
	DESK_AGREEMENT_VENDOR_EDIT: '/desk/agreement/vendor/edit/:agreementId',
	// 佣金维护
	DESK_COMMISSION: '/desk/commission',
	DESK_COMMISSION_LIST: '/desk/commission/list',
	DESK_COMMISSION_CREATE: '/desk/commission/create',
	DESK_COMMISSION_EDIT: '/desk/commission/find/id/:hierarchiesId',
	// 消息
	DESK_MESSAGE: '/desk/message',
	DESK_MESSAGE_LIST: '/desk/message/list',
	DESK_MESSAGE_CREATE: '/desk/message/create',
	DESK_MESSAGE_EDIT: '/desk/message/edit/:messageId',
	// 建议书/投保单/保单
	DESK_POLICY: '/desk/policy',
	DESK_POLICY_LIST: '/desk/policy/order/list',
	DESK_POLICY_URL_LIST: '/desk/policy/url/list',
	DESK_POLICY_OSS_URL: '/desk/policy/oss/url',
	DESK_ADVISE_LIST: '/desk/policy/advise/list',
	DESK_POLICY_CREATE: '/desk/policy/create',
	DESK_POLICY_VIEW: '/desk/policy/view/:policyId',
	DESK_STATISTICS: '/desk/statistics',
	DESK_POLICY_DOWNLOAD: '/desk/statistics/policy/download',
	DESK_SERVICE_FEE_DOWNLOAD: '/desk/statistics/fee/download',

	//保司业务
	INSURANCE_COMPANY: '/desk/insurance',
	INSURANCE_COMPANY_BUSSINESS_HQ: '/desk/insurance/HQOrderCheck',
	ORDER_CHECK_VIEW: '/desk/insurance/orderCheck/view/:imageAuditId',
	ORDER_CHECK_APPLY: '/desk/insurance/orderCheck/apply/:imageAuditId',

	//实名认证纪录
	DESK_IDENTIRY_CERTIFICATION: '/desk/certification',


	// 建议书/投保单/保单同步日志
	DESK_POLICY_NOTIFY_LOG: '/desk/policy-log',
	DESK_POLICY_NOTIFY_LOG_LIST: '/desk/policy-log/list',
	DESK_POLICY_NOTIFY_LOG_VIEW: '/desk/policy-log/view/:eventLogId',
	DESK_POLICY_NOTIFY_LOG_POLICY_VIEW: '/desk/policy-log/policy/view/:eventProcessLogId',

	DESK_POLICY_PROBLEM: '/desk/policy-problem',

	// 对账合约维护
	DESK_STATEMENT: '/desk/statement',
	DESK_STATEMENT_AGREEMENT: '/desk/statement/agreement',
	DESK_STATEMENT_AGREEMENT_LIST: '/desk/statement/agreement/list',
	DESK_STATEMENT_AGREEMENT_CREATE: '/desk/statement/agreement/create',
	DESK_STATEMENT_AGREEMENT_EDIT: '/desk/statement/agreement/edit/:agreementId',
	DESK_STATEMENT_AGREEMENT_VIEW: '/desk/statement/agreement/view/:agreementId',
	DESK_STATEMENT_COMMISSION: '/desk/statement/commission',
	DESK_STATEMENT_COMMISSION_MAINTAIN: '/desk/statement/commission/maintain',
	DESK_STATEMENT_BILL: '/desk/statement/bill',
	DESK_STATEMENT_BILL_MAINTAIN: '/desk/statement/bill/maintain',
	DESK_STATEMENT_BILL_RESULT_LIST: '/desk/statement/bill/result-list',

	// 客户
	DESK_CUSTOMER: '/desk/customer-query',
	DESK_CUSTOMER_LIST: '/desk/customer-query/list',
	DESK_CUSTOMER_VIEW: '/desk/customer-query/detail/:jointId',
	// 异步同步保单
	DESK_ASYNC_POLICY: '/desk/async-policy',
	// dashboard
	DESK_DASHBOARD: '/desk/dashboard',
	// 短信查询
	DESK_SMS: '/desk/sms',
	DESK_SMS_BLACK_LIST: '/desk/sms/blackList',
	// 代理人同步查询
	DESK_AGENT_SYNC_QUERY: '/desk/agent-sync-query',
	// 团单
	DESK_GROUP_POLICY: '/desk/group-policy',
	DESK_GROUP_POLICY_LIST: '/desk/group-policy/list',
	DESK_GROUP_POLICY_VIEW: '/desk/group-policy/view/:policyId',
	DESK_GROUP_POLICY_CREATE: '/desk/group-policy/create/:productId',
	// 课程管理
	DESK_TRAINING: '/desk/training/',
	DESK_COURSE_LIST: '/desk/training/course/list',
	DESK_COURSE_CREATE: '/desk/training/course/create',
	DESK_COURSE_EDIT: '/desk/training/course/edit/:courseId',
	DESK_QUIZ_LIST: '/desk/training/quiz/list',
	DESK_QUIZ_CREATE: '/desk/training/quiz/create',
	DESK_QUIZ_EDIT: '/desk/training/quiz/edit/:quizId',
	DESK_ACCOUNT_QUIZ_LIST: '/desk/training/quiz/current',
	DESK_ACCOUNT_COURSE_LIST: '/desk/training/course/current',
	// 日志管理
	DESK_AUDIT_LOG: '/desk/audit-log',
	DESK_AUDIT_LOG_LIST: '/desk/audit-log/list',



	AGENT_INDEX: '/agent/',
	AGENT_SIGN: '/agent/sign',
	AGENT_SIGN_IN: '/agent/sign/in',
	AGENT_HOME: '/agent/home',
	AGENT_ME: '/agent/me',
	AGENT_HB: '/agent/hb',
	AGENT_ENTERPRISE_CREATE: '/agent/enterprise/create',
	AGENT_ENTERPRISE_SHOW: '/agent/enterprise/show',
	AGENT_PERSONINFO_SETTING: '/agent/personinfo-setting',
	AGENT_PHONECHANGE: '/agent/phone-change',
	AGENT_ACCOUNTPASS_CHANGE: '/agent/accoutpass-change',
	AGENT_SETTING: '/agent/setting',
	AGENT_MYPOLICY: '/agent/my-policy',
	AGENT_MYPOLICY_ALL: '/agent/my-policy/all',
	AGENT_MYPOLICY_ISSUED: '/agent/my-policy/issued',
	AGENT_MYPOLICY_WAIT_PAID: '/agent/my-policy/wait-paid',
	AGENT_MYPOLICY_WAIT_COMPLETE: '/agent/my-policy/wait-complete',
	AGENT_MYPOLICY_INVALID: '/agent/my-policy/invalid',
	AGENT_BIND_MOBILE: '/agent/bindmobile/bind-mobile',
	AGENT_REGISTRY: '/agent/registry',

	// 手机
	// 手机登录
	MOBILE_SIGN: '/mobile/sign',
	MOBILE_SIGN_IN: '/mobile/sign/in',
	MOBILE_SIGN_UP: '/mobile/sign/up',
	//北京海力登录
	MOBILE_SIGN_BJHL: '/mobile/sign/bjhl',
	// 手机首页
	MOBILE_HOME: '/mobile',
	// 手机产品库
	MOBILE_CATEGORY: '/mobile/category',
	// 手机发现
	MOBILE_EXPLORE: '/mobile/explore',
	// 手机产品列表
	MOBILE_PRODUCT_SEARCH_BY_KEYWORD: '/mobile/product/search/keyword/:keyword',
	MOBILE_PRODUCT_SEARCH_BY_3RD_TAGS: '/mobile/product/search/tags/3rd/:tags',
	MOBILE_PRODUCT_SEARCH_BY_VENDORS: '/mobile/product/search/vendors/:vendorIds',
	MOBILE_PRODUCT_SEARCH_BY_ADVICE: '/mobile/product/search/advice',
	// 手机各种订单列表
	MOBILE_POLICY_ADVICE: '/mobile/policy/advice/:vendorCode/:productCode/:productVersion',
	MOBILE_POLICY_ADVICE_DIRECT: '/mobile/policy/advice/:vendorCode/:productCode/:productVersion/direct',
	MOBILE_POLICY_QUOTE: '/mobile/policy/quote/:vendorCode/:productCode/:productVersion',
	MOBILE_POLICY_QUOTE_DIRECT: '/mobile/policy/quote/:vendorCode/:productCode/:productVersion/direct',
	MOBILE_POLICY_INFO: '/mobile/policy/info/:vendorCode/:productCode/:productVersion',
	MOBILE_POLICY_PICCGI_GXYLYHB_INFO: '/mobile/policy-picc_gi-gxylyhb/info/:vendorCode/:productCode/:productVersion',
	// 手机各种产品页面
	MOBILE_POLICY_INFO_READONLY: '/mobile/policy/info/ro/:vendorCode/:productCode/:productVersion',
	MOBILE_POLICY_INFO_SHAREONLY: '/mobile/policy/info/so/:vendorCode/:productCode/:productVersion',
	MOBILE_POLICY_INFO_UNLOGIN: '/mobile/policy/info/unlogin/:vendorCode/:productCode/:productVersion/:tenantCode',
	MOBILE_POLICY_INFO_SHOW: '/mobile/policy/info/show/:vendorCode/:productCode/:productVersion/:tenantCode',
	MOBILE_POLICY_VIEW_BYQUOTE: '/mobile/policy/view/byquote/:quoteNo',
	MOBILE_POLICY_VIEW_BYQUOTE_LVJIAN: '/mobile/policy-ccic/view/byquote/:quoteNo',
	MOBILE_POLICY_VIEW_BYQUOTE_HMB: '/mobile/policy-picc_gi-gxylyhb/view/byossquote/:quoteNo/EKANGJI/GXYLYHB',
	MOBILE_POLICY_PICCGI_HNHBHHB2022_INFO: '/mobile/policy-picc_gi-hnhbhhb2022/info/:vendorCode/:productCode/:productVersion',
	MOBILE_POLICY_VIEW_BYOSSQUOTE_PICCGI_HNHBHHB2022: '/mobile/policy-picc_gi-hnhbhhb2022/view/byossquote/:quoteNo/EKANGJI/HNHBHHB2022',
	MOBILE_POLICY_VIEW_READONLY_BYQUOTE: '/mobile/policy/view/ro/byquote/:quoteNo',
	MOBILE_POLICY_ADVICE_READONLY: '/mobile/policy/advice/ro/:quoteNo',
	MOBILE_POLICY_ADVICE_TO_QUOTE: '/mobile/policy/advice/toquote/:quoteNo',
	MOBILE_POLICY_ADVICE_TO_QUOTE_NO_ID: '/mobile/policy/advice/toquote',
	MOBILE_POLICY_APPLY_PENDING: '/mobile/policy/apply-pending',
	MOBILE_POLICY_APPLY_SUCCESS: '/mobile/policy/apply-success',
	MOBILE_POLICY_INSURED_CALLBACK: '/mobile/policy/insuredCallBack/:quoteNo',
	MOBILE_POLICY_HOLDER_CALLBACK: '/mobile/policy/holderCallBack/:quoteNo',
	MOBILE_POLICY_PAY_SUCCESS: '/mobile/policy/pay-success/:quoteNo',
	MOBILE_POLICY_PAY_TRADING: '/mobile/policy/pay-trading/:quoteNo',
	MOBILE_POLICY_APPLY_FAIL: '/mobile/policy/apply-fail',
	MOBILE_POLICY_WECHAT_MINI_TRANSITION: '/mobile/policy/wechat-mini-transition',
	MOBILE_POLICY_SIGNATURE: '/mobile/policy/signature/:signatureId',
	// 手机我的
	MOBILE_ME: '/mobile/me',
	MOBILE_ME_MY_POLICY: '/mobile/me/my-policy',
	MOBILE_ME_MY_QUOTE: '/mobile/me/my-quote',
	MOBILE_ME_MY_ADVICE: '/mobile/me/my-advice',
	MOBILE_ME_POLICY_QUERY: '/mobile/me/policy/query',
	MOBILE_ME_FAV: '/mobile/me/fav',
	MOBILE_ME_CUSTOMER: '/mobile/me/customer',
	MOBILE_ME_CUSTOMER_VIEW: '/mobile/me/customer/view/:info',
	MOBILE_ME_CUSTOMER_POLICY: '/mobile/me/customer/policy/:info',
	// SUSPEND  此功能暂停开放, 目前无使用租户 20190321 Brad Wu
	// MOBILE_ME_CLAIM_CASE: '/mobile/me/claim/case',
	// MOBILE_ME_CLAIM_QUERY: '/mobile/me/claim/query',
	// MOBILE_ME_TRAINING: '/mobile/me/training',
	MOBILE_ME_DASHBOARD: '/mobile/me/dashboard',
	MOBILE_ME_CHANGE_THEME: '/mobile/me/change-theme',
	MOBILE_ME_CHANGE_PWD: '/mobile/me/change-pwd',
	MOBILE_ME_PROFILE: '/mobile/me/profile',
	// SUSPEND  此功能暂停开放, 目前无使用租户 20190321 Brad Wu
	// MOBILE_ME_STORE: '/mobile/me/store'
	MOBILE_POLICY: '/mobile/policy',

	MOBILE_STANDALONE: '/mobile/standalone',
	MOBILE_STANDALONE_ADVICE: '/mobile/standalone/advice',
	MOBILE_STANDALONE_QUOTE: '/mobile/standalone/quote',
	MOBILE_STANDALONE_POLICY: '/mobile/standalone/policy',
	MOBILE_STANDALONE_ORDER: '/mobile/standalone/orders',
	MOBILE_STANDALONE_PRODUCTS: '/mobile/standalone/products',
	MOBILE_STANDALONE_KEYWORD_PRODUCTS: '/mobile/standalone/products/keyword/:keyword',
	MOBILE_STANDALONE_VENDOR_PRODUCTS: '/mobile/standalone/vendorProducts/:vendorCode',
	MOBILE_STANDALONE_FIRSTTAG_PRODUCTS: '/mobile/standalone/firstTagProducts',
	MOBILE_STANDALONE_QUERY: '/mobile/standalone/query',

	DOCS_HOME: '/docs',

	MALL_INDEX: '/mall/',
	MALL_HOME: '/mall/home/',
	MALL_HOME_VENDOR: '/mall/home/:vendor',
	MALL_ME: '/mall/me',
	MALL_ME_VENDOR: '/mall/me/:vendor',
	MALL_ME_HOME: '/mall/me-home',
	MALL_ABOUTUS: '/mall/about-us',
	MALL_COPYRIGHT: '/mall/copyright',
	MALL_PRIVACY: '/mall/privacy',
	MALL_DISCLOSURE: '/mall/disclosure',
	Mall_COOPERATIVE: '/mall/cooperative',
	Mall_PRODUCTINFO: '/mall/productinfo',
	Mall_PERMITS: '/mall/permit',
	Mall_BUSINESS: '/mall/business',
	Mall_BUSINESSLICENSE: '/mall/businesslicense',
	Mall_LONGTERMCLAUSE: '/mall/longtermclause',
	Mall_LONGTERMRATE: '/mall/longtermrate',
	Mall_DRUGCLAUSE: '/mall/drugclause',
	Mall_DRUGRATE: '/mall/drugrate',
	Mall_PEACECLAUSE: '/mall/peaceclause',
	Mall_PEACERATE: '/mall/peacerate',
	MALL_POLICY: '/mall/policy',
	MALL_KEYWORD_PRODUCTS: '/mall/products/keyword/:keyword',
	MALL_PROMOTION_PRODUCTS: '/mall/products/promotion',
	MALL_TAG_PRODUCTS: '/mall/products/tag/:tag',
	MALL_MOBILE_SIGN_IN: '/mall/sign/in',
	MALL_MOBILE_SIGN_IN_VENDOR: '/mall/sign/in/:vendor',
	MALL_POLICY_FOR_SHARE: '/mall/services/share-validate/:quoteNo',
	MALL_BIND_MOBILE: '/mall/bindmobile/bind-mobile',
	MALL_TOC: '/mall/toC',
	Mall_TOC_ICON: '/mall/toCIcon',
	MALL_TOC_PERSONALINFO: '/mall/tocPersonalInfo',
	MALL_TOC_PERSONALINFO_DETAIL: '/mall/tocPersonalInfoDetail',
	MALL_TOC_UNBOUND_MOBILENO: '/mall/unboundMobileNo',
	MALL_TOC_POLICY: '/mall/tocpolicy',
	MALL_TOC_ORDER: '/mall/tocorder',
	MALL_JSCN: '/mall/jscn',
	MALL_JSCN_ORDER: '/mall/jscnOrder',
	MALL_APPOINT: '/mall/appoint',

	Mall_FORGET_PASSWORD: '/mall/forgetpassword',
	Mall_SIGN_UP: '/mall/sign/up',
	Mall_SIGN_UP_VENDOR: '/mall/sign/up/:vendor',
	MALL_ME_CHANGE_PWD: '/mall/me/change-pwd',
	MALL_PRODUCT: '/mall/product',
	MALL_PRODUCT_VENDOR: '/mall/product/:vendor',
	MALL_ABOUT_US: '/mall/about-us',
	MALL_DISCLO_SURE: '/mall/disclosure',
	MALL_HELP: '/mall/help',
	MALL_ABOUT_US_VENDOR: '/mall/about-us/:vendor',
	MALL_DISCLO_SURE_VENDOR: '/mall/disclosure/:vendor',
	MALL_HELP_VENDOR: '/mall/help/:vendor',
	MALL_BUSSINESS_QUAL: '/mall/bussiness-qualification',
	MALL_PRIVACY_POLICY: '/mall/privacy/policy',
	MALL_TRADE_INFORMATION: '/mall/trade-infomation',
	MALL_TRADE_SAFETY: '/mall/trade-safety',
	MALL_COPYRIGHT_NOTICE: '/mall/copyright-notice',
	MALL_SETTING: '/mall/setting',
	MALL_SETTING_VENDOR: '/mall/setting/:vendor',
	MALL_FAMILY_MEMBER:'/mall/family-member',
	MALL_FAMILY_MEMBER_LIST:'/mall/family-member/list',
	MALL_FAMILY_MEMBER_LIST_VENDOR: '/mall/family-member/list/:vendor',
	MALL_FAMILY_MEMBER_CREATE:'/mall/family-member/create',
	MALL_FAMILY_MEMBER_EDIT:'/mall/family-member/edit/:listId',
	MALL_MYPOLICY: '/mall/my-policy',
	MALL_MYPOLICY_ALL: '/mall/my-policy/all',
	MALL_MYPOLICY_ISSUED: '/mall/my-policy/issued',
	MALL_MYPOLICY_WAIT_PAID: '/mall/my-policy/wait-paid',
	MALL_MYPOLICY_WAIT_COMPLETE: '/mall/my-policy/wait-complete',
	MALL_MYPOLICY_INVALID: '/mall/my-policy/invalid',
	MALL_MMOREPOLICY: '/mall/morePolicy',
	MALL_PERSONINFO_SETTING: '/mall/personinfo-setting',
	MALL_PERSONINFO_SETTING_VENDOR: '/mall/personinfo-setting/:vendor',
	MALL_PHONECHANGE: '/mall/phone-change',
	MALL_ACCOUNTPASS_CHANGE: '/mall/accoutpass-change',
	MALL_COOPERATION_INSURANCE: '/mall/cooperation-insurance',
	MALL_INTERNET_INSURANCE_PRODUCTS: '/mall/internet-insurance-products',
	MALL_POLICY_CHECK_TRUE: '/mall/policy-check-true',
	MALL_PERSONAL_AGREEMENT: '/mall/personal-agreement',
	MALL_PERSONAL_STATEMENT: '/mall/personal-statement',
	MALL_SEARCH_PRODUCT: '/mall/search-product',
	MALL_SEARCH_PRODUCT_KEY_WORD: '/mall/search-product/:keyword',
	MALL_ENTERPRISES_SELECT: '/mall/enterprises-select',
	MALL_CUSTOMER_SERVICE: '/mall/customer-service',
	MALL_CUSTOMER_SERVICE_VENDOR: '/mall/customer-service/:vendor',

	MALL_MOBILE_GW_SIGN_IN: '/mall/gw/sign/in',
	MALL_GW_HOME: '/mall/gw/home',
	MALL_GW_ME: '/mall/gw/me',

	//理赔相关
	MALL_CLAIM: '/mall/claim',
	MALL_CLAIM_APPLICATION: '/mall/claim/application/:policyId',
	MALL_CLAIM_GROUP_APPLICATION: '/mall/claim/group-application/:policyId/:insuredParGpId',
	MALL_CLAIM_VIEW:'/mall/claim/view/:caseId',

	// 理赔申请-保单选择
	MALL_CLAIM_APPLY: '/mall/claim/policy-choice',
	// 理赔查询
	MALL_CLAIM_QUERY: '/mall/claim/query',
	// 履践-理赔查询-列表
	MALL_CLAIM_QUERY_LIST: '/mall/claim/query/list',
	// 履践-理赔查询-查看详情
	MALL_CLAIM_QUERY_VIEW: '/mall/claim/query/view/:caseId',

	MOBILE_RECOGNIZE: '/mobile/recognize',
	MOBILE_RECOGNIZE_DETECT: '/mobile/recognize/detect/:quoteNo/:name/:cardId/:userType',
	MOBILE_RECOGNIZE_DETECT_LIVING: '/mobile/recognize/living/:quoteNo/:name/:cardId',
	MOBILE_RECOGNIZE_DETECT_RESULT: '/mobile/recognize/result/:result',
	//履践
	// 退保
	SURRENDERAPPLICATION: "/mall/surrenderapplication",
	SURRENDERAPPLICATION_LIST: "/mall/surrenderapplication/list",
	SURRENDERAPPLICATION_EDIT: "/mall/surrenderapplication/edit/:quoteNo",
	SURRENDERAPPLICATION_SUCCEED: "/mall/surrenderapplication/succeed",
	// 工作变更
	JOBCHANGE: "/mall/jobchange",
	JOBCHANGE_LIST: "/mall/jobchange/list",
	JOBCHANGE_EDIT: "/mall/jobchange/edit/:quoteNo",
	JOBCHANGE_SUCCEED: "/mall/jobchange/succeed",
	// 转新保
	TURN_NEW_INSURANCE: "/mall/turn-new-insurance",
	TURN_NEW_INSURANCE_QUESTIONNAIRE: "/mall/turn-new-insurance/questionnaire",
	TURN_NEW_INSURANCE_MESSGAE_ENTER: "/mall/turn-new-insurance/message-enter",
	TURN_NEW_INSURANCE_SUCCEED: "/mall/turn-new-insurance/succeed",
	// 批改查询
	CORRECTING_QUERY: "/mall/correcting-query",

	//海报相关
	POSTER_POSTER_CREATE: '/agent/poster/create/:posterId',
	POSTER_POSTER_SHOW: '/agent/poster/show/:posterId/:promoter',


	// 微信账号绑定
	MALL_WECHAT_ACCOUNT_BIND: "/mall/wechat-account/binding",
	MALL_WECHAT_ACCOUNT_BIND_SUCCESS: '/mall/wechat-account/bind-success',

} as PATHS;
