import { PolicyModel, WidgetHelped } from '@my-types';
import { DateFNS } from './3rd';
import Consts from './consts';
import PATH from './path';
import ChineseFirstPy from './chinese-first-py';
import * as HostBy from './host-by';
import crypto from 'crypto';

const ALGORITHM = 'aes-128-ecb';
const SECURE_KEY = 'zbcdyrf123456789';
const SECURE_IV = '';
const AES_PREFIX = 'aes';

type QS = {
	ticket?: string | null;
	token?: string | null;
	delegated?: string | null;
	theme?: string | null;
	authFailTo?: string | null;
	rootUrlFrom?: string | null;
	urlFrom?: string | null;
	parcel?: string | null;
	openid?: string | null;
	planCode?: string | null;
	benefitLevel?: string | null;
	miniProgramSource?: string | null;
	sourceType?: string | null;//保单来源
	thirdDeskHome?: string;
	thirdDeskLogin?: string;
	actionType?: 'share';
	gluttonTraceNo?: string;
	declaType?: string;//是否跳转第三方健康告知且回调
	channel?: string | null;//保单渠道
	enabled?: string; //代理人是否在第三方是否具备该产品的销售权限
	functionType?: string; //支持类型
	vendor?: string;
	pageStep?: string | null;//页面跳转step
	directUrl?: string;//直接跳转的页面
	policyId?: string; // 履践
};

/**
 * copy from https://github.com/AceMetrix/jquery-deparam
 */
const deparam = (params?: string | null, coerce: boolean = false) => {
	var obj: any = {};
	var coerce_types: any = { true: !0, false: !1, null: null };

	// If params is an empty string or otherwise falsy, return obj.
	if (!params) {
		return obj;
	}

	// Iterate over all name=value pairs.
	params
		.replace(/\+/g, ' ')
		.split('&')
		.forEach(function (v) {
			var param: string[];
			let firstEq = v.indexOf('=');
			let lastEq = v.lastIndexOf('=');
			//只有一个等号
			if (firstEq == lastEq) {
				param = v.split('=');
			} else {
				param = [
					v.substring(0, firstEq),
					v.substring(firstEq + 1)
				]
			}
			var key: string = decodeURIComponent(param[0]);
			var val: any;
			var cur: any = obj;
			var i: number = 0;
			// If key is more complex than 'foo', like 'a[]' or 'a[b][c]', split it
			// into its component parts.
			var keys: any[] = key.split('][');
			var keys_last: number = keys.length - 1;

			// If the first keys part contains [ and the last ends with ], then []
			// are correctly balanced.
			if (/\[/.test(keys[0]) && /\]$/.test(keys[keys_last])) {
				// Remove the trailing ] from the last keys part.
				keys[keys_last] = keys[keys_last].replace(/\]$/, '');

				// Split first keys part into two parts on the [ and add them back onto
				// the beginning of the keys array.
				keys = keys
					.shift()
					.split('[')
					.concat(keys);

				keys_last = keys.length - 1;
			} else {
				// Basic 'foo' style key.
				keys_last = 0;
			}

			// Are we dealing with a name=value pair, or just a name?
			if (param.length === 2) {
				val = decodeURIComponent(param[1]);

				// Coerce values.
				if (coerce) {
					val =
						val && !isNaN(val) && +val + '' === val
							? +val // number
							: val === 'undefined'
								? undefined // undefined
								: coerce_types[val] !== undefined
									? coerce_types[val] // true, false, null
									: val; // string
				}

				if (keys_last) {
					// Complex key, build deep object structure based on a few rules:
					// * The 'cur' pointer starts at the object top-level.
					// * [] = array push (n is set to array length), [n] = array if n is
					//   numeric, otherwise object.
					// * If at the last keys part, set the value.
					// * For each keys part, if the current level is undefined create an
					//   object or array based on the type of the next keys part.
					// * Move the 'cur' pointer to the next level.
					// * Rinse & repeat.
					for (; i <= keys_last; i++) {
						key = keys[i] === '' ? cur.length : keys[i];
						cur = cur[key] =
							i < keys_last
								? cur[key] || (keys[i + 1] && isNaN(keys[i + 1]) ? {} : [])
								: val;
					}
				} else {
					// Simple key, even simpler rules, since only scalars and shallow
					// arrays are allowed.

					if (Object.prototype.toString.call(obj[key]) === '[object Array]') {
						// val is already an array, so push on the next value.
						obj[key].push(val);
					} else if ({}.hasOwnProperty.call(obj, key)) {
						// val isn't an array, but since a second value has been specified,
						// convert val into an array.
						obj[key] = [obj[key], val];
					} else {
						// val is a scalar.
						obj[key] = val;
					}
				}
			} else if (key) {
				// No value was defined, so set something meaningful.
				obj[key] = coerce ? undefined : '';
			}
		});

	return obj;
};

/**
 * copy from https://github.com/knowledgecode/jquery-param
 */
const param = (a: any): string => {
	var s: string[] = [];
	var add = function (k: string, v: any) {
		v = typeof v === 'function' ? v() : v;
		v = v === null ? '' : v === undefined ? '' : v;
		s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
	};
	var buildParams = function (prefix: string, obj: any) {
		var i, len, key;

		if (prefix) {
			if (Array.isArray(obj)) {
				for (i = 0, len = obj.length; i < len; i++) {
					buildParams(
						prefix + '[' + (typeof obj[i] === 'object' && obj[i] ? i : '') + ']',
						obj[i]
					);
				}
			} else if (String(obj) === '[object Object]') {
				for (key in obj) {
					buildParams(prefix + '[' + key + ']', obj[key]);
				}
			} else {
				add(prefix, obj);
			}
		} else if (Array.isArray(obj)) {
			for (i = 0, len = obj.length; i < len; i++) {
				add(obj[i].name, obj[i].value);
			}
		} else {
			for (key in obj) {
				buildParams(key, obj[key]);
			}
		}
		return s;
	};

	return buildParams('', a).join('&');
};

export const MobileSigninPageEntries = {
	BJHL: PATH.MOBILE_SIGN_BJHL
} as { [key: string]: string };

/**
 * 工具类
 */
class Utils {
	/**
	 * 将指定的参数转换为数组.
	 * 如果参数是数组, 直接返回.
	 * 如果参数是null/undefined, 返回空数组.
	 * 其他返回只包含一个元素(参数本身)的数组
	 */
	toArray<T>(any: null | undefined | T | T[]): T[] {
		return Array.isArray(any) ? any : any == null ? [] : [any];
	}
	/**
	 * 产生一个包含指定元素和长度的数组
	 */
	times<T>(element: T, times?: number): T[] {
		const ret = new Array<T>(times || 1);
		ret.fill(element, 0, ret.length);
		return ret;
	}
	/**
	 * 截取日期
	 */
	truncateAsDate(date: string): string {
		return date ? date.substring(0, 11) : date;
	}
	/**
	 * 对指定时间进行计算. 如果指定时间为null, 使用当前时间.
	 * offset格式: /[yMdhms][+-]?\d+/
	 */
	calculateDate(date: string | Date, offset: string): string {
		if (date === null) {
			date = new Date();
		} else if (this.isString(date)) {
			date = DateFNS.parse(date);
		}
		const regexp = /[yMdhms][+-]?\d+/g;
		const partRegexp = /([yMdhms])([+-]?)(\d+)/;
		const value = offset.match(regexp)!.reduce((date, operation) => {
			const match = operation.match(partRegexp)!;
			const part = match[1];
			const type = match[2];
			const variable = (match[3] as any) * (type === '-' ? -1 : 1);
			switch (part) {
				case 'y':
					return type === ''
						? DateFNS.setYear(date, variable)
						: DateFNS.addYear(date, variable);
				case 'M':
					return type === ''
						? DateFNS.setMonth(date, variable)
						: DateFNS.addMonth(date, variable);
				case 'd':
					return type === ''
						? DateFNS.setDayOfMonth(date, variable)
						: DateFNS.addDay(date, variable);
				case 'h':
					return type === ''
						? DateFNS.setHour(date, variable)
						: DateFNS.addHour(date, variable);
				case 'm':
					return type === ''
						? DateFNS.setMinute(date, variable)
						: DateFNS.addMinute(date, variable);
				case 's':
					return type === ''
						? DateFNS.setSecond(date, variable)
						: DateFNS.addSecond(date, variable);
				default:
					throw new Error(
						`Field[${type}] in offset[${offset}] for date calculation is not supported yet.`
					);
			}
		}, date);

		return DateFNS.format(value, Consts.STANDARD_DATETIME_FORMAT);
	}
	/**
	 * 金额格式化
	 */
	formatAmount(currency?: number | string): string {
		if (currency != null) {
			return (currency + '').toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
		} else {
			return '';
		}
	}
	isEmpty(o?: string | number | null): o is null | undefined {
		return !this.isNumber(o) && (this.isNull(o) || this.isUndefined(o) || o.length === 0);
	}
	isBlank(o?: string | number | null): o is string | null | undefined {
		return this.isEmpty(o) || (this.isString(o) && (o as string).trim().length === 0);
	}
	/**
	 * 是否字符串
	 */
	isString(o: any): o is string {
		return Object.prototype.toString.call(o).slice(8, -1) === 'String';
	}
	/**
	 * 是否数字
	 */
	isNumber(o: any): o is number {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Number';
	}
	/**
	 * 是否boolean
	 * @param {*} o
	 */
	isBoolean(o: any): o is boolean {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Boolean';
	}
	/**
	 * 是否函数
	 * @param {*} o
	 */
	isFunction(o: any): o is Function {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Function';
	}
	/**
	 * 是否为null
	 * @param {*} o
	 */
	isNull(o: any): o is null {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Null';
	}
	/**
	 * 是否undefined
	 */
	isUndefined(o: any): o is undefined {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Undefined';
	}
	/**
	 * 是否对象
	 */
	isObject(o: any): o is object {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Object';
	}
	/**
	 * 是否数组
	 */
	isArray(o: any): o is any[] {
		return Array.isArray(o);
	}
	/**
	 * 是否时间
	 */
	isDate(o: any): o is Date {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Date';
	}
	/**
	 * 是否正则
	 */
	isRegExp(o: any): o is RegExp {
		return Object.prototype.toString.call(o).slice(8, -1) === 'RegExp';
	}
	/**
	 * 是否错误对象
	 */
	isError(o: any): o is Error {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Error';
	}
	/**
	 * 是否Symbol函数
	 */
	isSymbol(o: any): o is Symbol {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Symbol';
	}
	/**
	 * 是否Promise对象
	 */
	isPromise(o: any): o is Promise<any> {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Promise';
	}
	/**
	 * 是否Set对象
	 */
	isSet(o: any): o is Set<any> {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Set';
	}
	/**
	 * 是否是FormData对象
	 */
	isFormData(o: any): o is FormData {
		return Object.prototype.toString.call(o).slice(8, -1) === 'FormData';
	}
	/**
	 * 可否可以判断为false
	 */
	isFalse(o: any): o is false {
		return !o || o === 'null' || o === 'undefined' || o === 'false' || o === 'NaN';
	}
	/**
	 * 可否可以判断为true
	 */
	isTrue(o: any): o is true {
		return !this.isFalse(o);
	}
	/**
	 * 指定参数, 则解析; 否则获取当前location的search, 并转换为JSON对象.
	 * 如果没有, 返回空JSON
	 */
	fromQueryString(qs?: string | null): QS {
		if (!this.isNull(qs) && !this.isUndefined(qs)) {
			return deparam(qs);
		} else {
			const search = window.location.search;
			if (search && search !== '?') {
				return deparam(search.substring(1));
			} else {
				return {};
			}
		}
	}
	/**
	 * 将指定的对象转换为query string
	 */
	toQueryString(obj: any): string {
		return param(obj);
	}
	/**
	 * 使用当前浏览器当前URI编码, 并且添加指定的query string
	 */
	encodeCurrentURI(qs?: string | null): string {
		if (this.isNull(qs) || this.isUndefined(qs)) {
			return encodeURIComponent(window.location.origin + window.location.pathname);
		} else {
			return encodeURIComponent(`${window.location.origin}${window.location.pathname}?${qs}`);
		}
	}
	/**
	 * 拼接姓名
	 *
	 * @param {string} firstName
	 * @param {string} lastName
	 */
	asName(firstName: string | null | undefined, lastName: string | null | undefined): string {
		if (this.hasOrientalChar(firstName) || this.hasOrientalChar(lastName)) {
			return (lastName || '') + (firstName || '');
		} else {
			return (firstName || '') + ' ' + (lastName || '');
		}
	}
	/**
	 * 是否包含东方字符
	 */
	hasOrientalChar(s: string | null | undefined): boolean {
		if (!s) {
			return false;
		}
		return (
			-1 !==
			s.split('').findIndex(char => {
				return char.charCodeAt(0) > 0x3040;
			})
		);
	}
	quitPolicyPage(invoker: WidgetHelped, policy: PolicyModel): void {
		const extensionData = policy.extensionData || {};
		if (extensionData.rootUrlFrom) {
			// 从其他地方跳过来的
			window.location.assign(extensionData.rootUrlFrom);
		} else if (extensionData.urlFrom) {
			// 从其他地方跳过来的
			const currentUrl = window.location.href.split('#')[0];
			if (extensionData.urlFrom !== currentUrl) {
				// 与当前url不一样, 直接跳
				window.location.assign(extensionData.urlFrom);
			} else if (window.location.href.indexOf('#') !== -1) {
				// 当前url有#, 退两步
				invoker
					.getHelpers()
					.getRouter()
					.go(-2);
			} else {
				invoker
					.getHelpers()
					.getRouter()
					.back();
			}
		} else {
			invoker
				.getHelpers()
				.getRouter()
				.back();
		}
	}
	/**
	 * 生成文件
	 * TODO 目前支持csv及txt格式, 后续如果需要生成其他格式文件, 例如图片, 再进行丰富及扩展
	 *
	 * @param fileName 文件名称
	 * @param fileType 文件类型, 例如'text/csv'
	 * @param fileContent 符合文件类型的文件内容
	 */
	downloadFile(fileName: string, fileType: string, fileContent: string): void {
		// @ts-ignore
		if (window.navigator.msSaveOrOpenBlob) {
			// if browser is IE
			fileContent = '\ufeff' + fileContent;
			const IEBlob = new Blob([decodeURIComponent(encodeURI(fileContent))], {
				type: fileType + ';charset=utf-8;'
			});
			//@ts-ignore
			navigator.msSaveBlob(IEBlob, fileName);
		} else {
			const link = document.createElement('a');
			// 解决大文件下载失败
			const blob = new Blob(['\ufeff' + fileContent], { type: fileType });
			link.setAttribute('href', URL.createObjectURL(blob));
			link.setAttribute('download', fileName);

			document.body.appendChild(link);
			link.click();
		}
	}

	//废弃
	base64ImagetoFile(base64Image: string, filename: string): File {
		//将base64转换为文件
		var arr = base64Image.split(',')!,
			mime = arr[0].match(/:(.*?);/)![1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}

	//将base64转化成file
	dataURLtoFile(dataurl: string, filename: string): File {
		return this.blobToFile(this.dataURLtoBlob(dataurl), filename);
	}

	//将base64转换为blob
	dataURLtoBlob(dataurl: string): any {
		var arr = dataurl.split(','),
			mime = (arr[0].match(/:(.*?);/) || ['', ''])[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	//将blob转换为file
	blobToFile(theBlob: any, fileName: string): any {
		theBlob.lastModifiedDate = new Date();
		theBlob.name = fileName;
		return theBlob;
	}


	transformPNG2JPEG(base64Image: string): Promise<string> {
		return new Promise(resolve => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d')!;

			const image = new Image();
			image.crossOrigin = 'anonymous';
			image.onload = function () {
				const { width, height } = image;
				canvas.width = width;
				canvas.height = height;
				ctx.fillStyle = '#fff';
				ctx.fillRect(0, 0, width, height);
				ctx.drawImage(image, 0, 0, width, height);
				resolve(canvas.toDataURL('image/jpeg', 1.0));
			};
			image.src = base64Image;
		});
	}
	copyTextToClipboard(s: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const textarea = document.createElement('textarea');
			// 一定要显示, 否则在模拟器上不能复制
			// textarea.style.display = 'none';
			textarea.style.position = 'fixed';
			textarea.style.height = '0';
			textarea.style.top = '-9999px';
			textarea.value = s;
			document.body.appendChild(textarea);
			textarea.select();
			textarea.setSelectionRange(0, s.length);
			const ret = document.execCommand('copy');
			document.body.removeChild(textarea);
			if (ret) {
				resolve();
			} else {
				reject();
			}
		});
	}
	copyTextToClipboardCompatibleIOS(s: string): Promise<void> {
		return new Promise((resolve, reject) => {
			var successful = true;
			//要复制的
			const textarea = document.createElement('textarea');
			// 一定要显示, 否则在模拟器上不能复制
			// textarea.style.display = 'none';
			textarea.style.position = 'fixed';
			textarea.style.height = '0';
			textarea.style.top = '-9999px';
			textarea.value = s;
			document.body.appendChild(textarea);
			if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
				textarea.style.cursor = "pointer";
				let selection = window.getSelection(); //这段代码必须放在前面否则无效
				if (selection) {
					selection.removeAllRanges();
					var range = document.createRange();
					// 选中需要复制的节点
					range.selectNode(textarea);
					// 执行选中元素
					selection.addRange(range);
					// 执行 copy 操作
					successful = document.execCommand("copy");
					// 移除选中的元素
					selection.removeAllRanges();
				}
			} else {
				textarea.select();
				textarea.setSelectionRange(0, s.length);
				successful = document.execCommand('copy');
			}
			document.body.removeChild(textarea);
			if (successful) {
				resolve();
			} else {
				reject();
			}
		});
	}
	getMobileSigninPagePath(tenantCode: string): string {
		const hostname = window.location.hostname;
		const { vendor } = this.fromQueryString();
		switch (hostname) {
			//需要跳转到商城登录的域名
			case 'mall-agency.eplbks.com':
			case 'mall-agency-uat.eplbks.com':
				return PATH.MALL_MOBILE_SIGN_IN;
			//美世的所有域名跳转此登录页
			case 'www.mej.cn':
			case 'mej-prod.ebaocloud.com.cn':
			case 'mej-preprod.ebaocloud.com.cn':
			case 'uat.mej.cn':
			case 'mej-tst.ebaocloud.com.cn':
			case 'mej-dev.ebaocloud.com.cn':
				return this.isBlank(vendor) ? PATH.MALL_MOBILE_SIGN_IN : `${PATH.MALL_MOBILE_SIGN_IN}?${this.toQueryString({ vendor })}`;
			default:
				return MobileSigninPageEntries[tenantCode] || PATH.MALL_MOBILE_SIGN_IN;
		}
	}
	log(message?: any, ...optionalParams: any[]): void {
		console.log(message, optionalParams);
	}
	info(message?: any, ...optionalParams: any[]): void {
		console.info(message, optionalParams);
	}
	trace(message?: any, ...optionalParams: any[]): void {
		console.trace(message, optionalParams);
	}
	warn(message?: any, ...optionalParams: any[]): void {
		console.warn(message, optionalParams);
	}
	error(message?: any, ...optionalParams: any[]): void {
		console.error(message, optionalParams);
	}
	table(...data: any[]): void {
		console.table(data);
	}

	validateIDCard(number: string) {
		if (!number) number = "";
		var card = number.trim();
		var str = "身份证";
		//是否为空
		if (card === '') {
			return '请输入' + str + '号';
		}
		//校验长度，类型
		if (this.isCardNo(card) === false) {
			return '您输入的' + str + '号码不正确，请重新输入';
		}
		//检查省份
		if (this.checkProvince(card) === false) {
			return '您输入的' + str + '号码不正确,请重新输入';
		}
		//校验生日
		if (this.checkBirthday(card) === false) {
			return '您输入的' + str + '号码生日不正确,请重新输入';
		}
	}
	//检查号码是否符合规范，包括长度，类型
	isCardNo(card: string) {
		//身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
		var reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/;
		if (reg.test(card) === false) {
			return false;
		}

		return true;
	};
	//取身份证前两位,校验省份
	checkProvince(card: string) {
		var vcity = {
			11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古",
			21: "辽宁", 22: "吉林", 23: "黑龙江", 31: "上海", 32: "江苏",
			33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南",
			42: "湖北", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆",
			51: "四川", 52: "贵州", 53: "云南", 54: "西藏", 61: "陕西", 62: "甘肃",
			63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外"
		};
		var province = card.substr(0, 2);
		//@ts-ignore
		if (this.isUndefined(vcity[province])) {
			return false;
		}
		return true;
	};
	//检查生日是否正确
	checkBirthday(card: string) {
		let len = card.length;
		//身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
		// eslint-disable-next-line
		if (len == 15) {
			let re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
			let arr_data = card.match(re_fifteen);
			//@ts-ignore
			let year = arr_data[2];
			//@ts-ignore
			let month = arr_data[3];
			//@ts-ignore
			let day = arr_data[4];
			let birthday = new Date('19' + year + '/' + month + '/' + day);
			return this.verifyBirthday('19' + year, month, day, birthday);
		}
		//身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
		// eslint-disable-next-line
		if (len == 18) {
			let re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
			let arr_data = card.match(re_eighteen);
			//@ts-ignore
			let year = arr_data[2];
			//@ts-ignore
			let month = arr_data[3];
			//@ts-ignore
			let day = arr_data[4];
			let birthday = new Date(year + '/' + month + '/' + day);
			return this.verifyBirthday(year, month, day, birthday);
		}
		return false;
	};

	//校验日期
	verifyBirthday(year: string, month: string, day: string, birthday: Date) {
		var now = new Date();
		var now_year = now.getFullYear();
		//年月日是否合理
		if (birthday.getFullYear() === Number(year) && (birthday.getMonth() + 1) === Number(month) && birthday.getDate() === Number(day)) {
			//判断年份的范围（0岁到105岁之间)
			//@ts-ignore
			var time = now_year - year;
			if (time <= 105 && now > birthday) {
				return true;
			}
			return false;
		}
		return false;
	};


	/**
	 * 获取当前ip地址，安卓能获取到，ios获取不到
	 */
	getIPs(): Promise<String> {
		const DEFAULT_IP = '114.114.114.114';
		return new Promise((resolve, reject) => {
			try {
				var pc = new RTCPeerConnection({ iceServers: [] }), noop = function () { };
				pc.createDataChannel('');//create a bogus data channel
				let options = {
					offerToReceiveAudio: false,
					offerToReceiveVideo: true,
					iceRestart: false
				} as RTCOfferOptions;

				pc.createOffer(options)
					.then((desc) => {
						pc.setLocalDescription(desc)
					})
					.catch((err) => {
						console.log(err)
					});

				pc.onicecandidate = function (ice) {
					if (ice && ice.candidate && ice.candidate.candidate) {
						var ips = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(ice.candidate.candidate);
						console.log('my IP: ', ips);
						if (ips && ips.length > 1) {
							resolve(ips[1]);
						} else {
							resolve(DEFAULT_IP);
						}
					} else {
						resolve(DEFAULT_IP);
					}
					pc.onicecandidate = noop;
				};
			} catch {
				resolve(DEFAULT_IP);
			}
		})


	};


	/**
   * 解决两个数相乘精度丢失问题
   * @param a
   * @param b
   * @returns {Number}
   */
	floatMul(a: number, b: number): number {
		var c = 0,
			d = a.toString(),
			e = b.toString();
		try {
			c += d.split(".")[1].length;
		} catch (f) { }
		try {
			c += e.split(".")[1].length;
		} catch (f) { }
		return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c);
	}

	/**
   * 解决两个数相除精度丢失问题
   * @param a
   * @param b
   * @returns
   */
	floatDiv(a: number, b: number): number {
		var c, d, e = 0,
			f = 0;
		try {
			e = a.toString().split(".")[1].length;
		} catch (g) { }
		try {
			f = b.toString().split(".")[1].length;
		} catch (g) { }
		return c = Number(a.toString().replace(".", "")), d = Number(b.toString().replace(".", "")), this.floatMul(c / d, Math.pow(10, f - e));
	}


	/**
	 * 前端分页
	 * @param pageIndex
	 * @param pageSize
	 * @param totalData
	 */
	getTableData(pageIndex: number = 1, pageSize: number = 10, totalData: any[] = []) {
		const { length } = totalData;
		const tableData = {
			items: [], //当前数值
			pageIndex, //第几页
			pageSize, //每页大小
			length //总长度
		} as {
			items: any[];
			pageIndex: number;
			pageSize: number;
			length: number;
		};
		if (pageSize >= length) {
			//pageSize大于等于总数据长度，说明只有1页数据或没有数据
			tableData.items = totalData;
			tableData.pageIndex = 1; //直接取第一页
		} else {
			//pageSize小于总数据长度，数据多余1页
			const num = pageSize * (pageIndex - 1); //计算当前页（不含）之前的所有数据总条数
			if (num < length) {
				//如果当前页之前所有数据总条数小于（不能等于）总的数据集长度，则说明当前页码没有超出最大页码
				const startIndex = num; //当前页第一条数据在总数据集中的索引
				const endIndex = num + pageSize - 1; //当前页最后一条数据索引
				tableData.items = totalData.filter(
					(_, index) => index >= startIndex && index <= endIndex
				); //当前页数据条数小于每页最大条数时，也按最大条数范围筛取数据
			} else {
				//当前页码超出最大页码，则计算实际最后一页的page，自动返回最后一页数据
				const size = parseInt(String(length / pageSize)); //取商
				const rest = length % pageSize; //取余数
				if (rest > 0) {
					//余数大于0，说明实际最后一页数据不足pageSize，应该取size+1为最后一条的页码
					tableData.pageIndex = size + 1; //当前页码重置，取size+1
					tableData.items = totalData.filter(
						(_, index) => index >= pageSize * size && index <= length
					);
				} else if (rest === 0) {
					//余数等于0，最后一页数据条数正好是pageSize
					tableData.pageIndex = size; //当前页码重置，取size
					tableData.items = totalData.filter(
						(_, index) => index >= pageSize * (size - 1) && index <= length
					);
				} //注：余数不可能小于0
			}
		}
		// console.log(tableData);
		return tableData;
	}

	//计算base64长度字符串的大小
	getBase64Size(base64: string): number {
		let len = base64.length;
		return len;
		// return Math.ceil(this.floatDiv(len, 4)) * 4;
	}


	getBase64Image(url: string): Promise<string> {
		return new Promise(resolve => {
			var image = new Image()
			let base64 = ''
			image.src = url
			image.crossOrigin = 'anonymous';

			image.onload = function () {
				var canvas = document.createElement('canvas');
				let width = image.width;
				let height = image.height;
				canvas.width = width;
				canvas.height = height;
				const ctx = canvas.getContext('2d')!;
				ctx.drawImage(image, 0, 0, width, height);
				base64 = canvas.toDataURL('image/jpeg')
				resolve(base64);
			};
		});
	}


	// getBase64Image (url: string): string {
	// debugger;
	//     var image = new Image()
	// let base64 = ''
	//     image.src = url
	//     image.crossOrigin = 'anonymous';
	//
	//     image.onload = function () {
	//     	var canvas = document.createElement('canvas');
	//     	let width = image.width;
	// 	let height = image.height;
	//         canvas.width = width;
	//         canvas.height = height;
	//         const ctx = canvas.getContext('2d')!;
	//         ctx.drawImage(image,0,0,width,height);
	//         base64 = canvas.toDataURL('image/jpeg')
	//     }
	//     return base64;
	// }

	/**转化为万为单位的数字 */
	transformTenThousand = (options: {
		value: number,
		precision?: number,
		unit?: string
	}): string => {
		const { value, precision = 0, unit = '' } = options;
		if (isNaN(value)) return '';
		let result = this.floatDiv(value, 10000).toFixed(precision);
		return [result, unit].join('');
	}

	/**
	 *
	 * @param value 是否是http://或https://开头的url地址
	 * @returns
	 */
	isHttpKey(value: string): boolean {
		return /^http[s]{0,1}:\/\/([\w.]+\/?)\S*/.test(value);
	}

	/**
	 *
	 * @param str 是否是base64字符传
	 * @returns
	 */
	isBase64Key(str: string): boolean {
		if (this.isBlank(str)) {
			return false;
		}
		//以data:开始的文件也认为是base64
		if (/^data:/.test(str) && (str as string).indexOf('base64') !== -1) return true;
		try {
			return btoa(atob(str)) == str;
		} catch (err) {
			return false;
		}
	}

	isWebassetKey(value: string): boolean {
		return /^\/webasset\/([\w.]+\/?)\S*/.test(value);
	}

	isStaticKey(value: string): boolean {
		return /^\/static\/([\w.]+\/?)\S*/.test(value);
	}


	//是否是sts的地址,不满足这3者的需要使用sts去请求
	isSTSKey(value: string): boolean {
		return this.isString(value) && !this.isBlank(value) && !this.isHttpKey(value) && !this.isBase64Key(value) && !this.isWebassetKey(value) && !this.isStaticKey(value);
	}

	/**
	 *
	 * @returns 生成32位uuid
	 */
	generateUUID() {
		var s = [] as any[];
		var hexDigits = "0123456789abcdef";
		for (var i = 0; i < 36; i++) {
			s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
		}
		s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
		s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
		s[8] = s[13] = s[18] = s[23] = "-";

		var uuid = s.join("");
		return uuid;
	}

	getTenanIdByHostName = () => {
		const hosted = HostBy.asHostBy();
		switch (hosted.by) {
			case HostBy.HostBy.YDL:
				return 20006;
			case HostBy.HostBy.SHHL:
				return 200471;
			case HostBy.HostBy.BJL:
				return 20259;
			case HostBy.HostBy.MYSELF:
				return 20006;
			default:
				return 20006;
		}
	};
	getFileNameFromPath(path = '') {
		let fileNameWithSuffix = path.split('/').pop(),
			name = fileNameWithSuffix?.split('.').shift();
		return name ? decodeURIComponent(name) : '';
	}
	getBirthday(card: string) {
		let len = card.length;
		//身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
		// eslint-disable-next-line
		if (len == 15) {
			let re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
			let arr_data = card.match(re_fifteen);
			//@ts-ignore
			let year = arr_data[2];
			//@ts-ignore
			let month = arr_data[3];
			//@ts-ignore
			let day = arr_data[4];
			let birthday = new Date('19' + year + '/' + month + '/' + day);
			return birthday;
		}
		//身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
		// eslint-disable-next-line
		if (len == 18) {
			let re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
			let arr_data = card.match(re_eighteen);
			//@ts-ignore
			let year = arr_data[2];
			//@ts-ignore
			let month = arr_data[3];
			//@ts-ignore
			let day = arr_data[4];
			let birthday = new Date(year + '/' + month + '/' + day);
			return birthday;
		}
		return '';
	}
	getGender(card: string) {
		let genderNumber = card.length === 15 ? card.substr(card.length - 1, 1) : card.substr(card.length - 2, 1);
		return parseInt(genderNumber) % 2 === 0 ? Consts.GENDER.FEMALE : Consts.GENDER.MALE;
	}
	isJson(str: string) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	isValidHttpUrl(str: any) {
		let url: any;
		try {
			url = new URL(str);
		} catch (_) {
			return false;
		}
		return url.protocol === "http:" || url.protocol === "https:";
	}
	//传入字符串数组[{value:'你好',letter:'N'}]
	pySegSort(array: {
		value: string;
		letter?: string;
	}[], speciallArray?: { value: string; letter: string }[]) {
		let newArr = [] as any;
		array.forEach(item => {
			const { value } = item;
			if (this.toArray(speciallArray).length > 0) {
				if (speciallArray?.findIndex(item => item.value == value) !== -1) {
					const special = speciallArray?.find(item => item.value == value);
					const letter = special?.letter;
					if (!this.isBlank(letter)) {
						let newItem = {} as any;
						//@ts-ignore
						newItem.letter = letter.toUpperCase();
						newItem.value = value;
						newArr.push(newItem);
						return;
					}
				}
			}
			//获取首字母数组
			// const logPinYins = ChineseFirstPy.makePy(value.substring(0, 1));
			// if (logPinYins.length > 1) {
			// 	console.log(`{ value:'${value}',letter:'${logPinYins}'},`);
			// }
			const pinYins = ChineseFirstPy.makePy(value.substring(0, 1));
			// console.log(pinYins);
			Array.from(new Set(pinYins)).forEach((pItem: any) => {
				let newItem = {} as any;
				if (!this.isBlank(pItem)) {
					const letter = pItem.substring(0, 1);
					newItem.letter = letter.toUpperCase();
					newItem.value = value;
					newArr.push(newItem);
				}
			});
		});
		return newArr;
	}

	maskStr(inputStr: string, firstKeepLen: number, lastKeepLen: number) {
		let str = '';
		if (!this.isBlank(inputStr)) {
			inputStr = inputStr + '';
			if (inputStr.length > (firstKeepLen + lastKeepLen)) {
				str = (firstKeepLen ? inputStr.slice(0, firstKeepLen) : '')
					+ new Array(inputStr.length - firstKeepLen - lastKeepLen).fill('*').join('')
					+ (lastKeepLen ? inputStr.slice(-Math.abs(lastKeepLen)) : '');
			} else {
				str = inputStr;
			}
		}
		return str;
	}

    /**
	 * 
	 * 加密
	 * @returns 
	 */
	frontEncry(data: string) {
		var cipher = crypto.createCipheriv(ALGORITHM, SECURE_KEY, SECURE_IV);
		let encrypted = `${cipher.update(
			data, 
			'utf8',
			'hex',
		)}${cipher.final('hex')}`;
		return AES_PREFIX + encrypted;
	}

	/**
	 * 解密
	 * @returns 
	 */
	frontDecry(encryptedText: string) {
		if(new RegExp(`^${AES_PREFIX}`).test(encryptedText)){
			encryptedText = encryptedText.substring(AES_PREFIX.length);
			let decipher = crypto.createDecipheriv(ALGORITHM, SECURE_KEY, SECURE_IV);
			let decrypted = decipher.update(encryptedText, 'hex', 'utf8');
			decrypted += decipher.final('utf8');
			return decrypted;
		}
		return encryptedText;
	}

	generateSmsDecryptedBody(decryptedBody:string){
		return this.frontEncry(decryptedBody+DateFNS.format(new Date(),'YYYYMMDDHHmmss'));
	}

}

export default new Utils();
