import { Envs, Utils } from '@common';
const oldUIProducts = {
	'1AN': {
		YASPAQZR: '001' //易安食品安全责任保险
	},
	HUAGUI: {
		HGDMDQSXMTJ: '001' //华贵大麦定期寿险(免体检版)
	}
} as { [key: string]: { [key: string]: string } };

export const onCSMSHandled = (options: {
	vendorCode: string;
	productCode: string;
	productVersion: string;
}): boolean => {
	const { vendorCode, productCode, productVersion } = options;
	return (oldUIProducts[vendorCode] || {})[productCode] === productVersion;
};

// 忽略校验合约的产品
const ignoreAgreementCheckProducts = {
	hongk: {
		HKXGZX: '001' //弘康佑安意外保险
	}
} as { [key: string]: { [key: string]: string } };

export const ignoreAgreementCheck = (options: {
	vendorCode: string;
	productCode: string;
	productVersion: string;
}): boolean => {
	const { vendorCode, productCode, productVersion } = options;
	return (ignoreAgreementCheckProducts[vendorCode] || {})[productCode] === productVersion;
};


//可回溯产品定义
const GluttonProducts = {
	FXJK: {
		'FXLJYS2019': { //复星联合乐健一生中端医疗保险（2019版）
			'001': {
				EPLBKS: true,
			}
		},
		'FXMMBB': { //复星联合妈咪保贝少儿重大疾病保险
			'001': {
				EPLBKS: true,
			}
		},
		'FXMMBBXSB': { //复星联合妈咪保贝（新生版）少儿重大疾病保险
			'001': {
				EPLBKS: true,
			}
		},
		'FXBJX': { //复星联合倍吉星重大疾病保险
			'001': {
				EPLBKS: true,
			}
		},
		'FXYSWY2021': { //复星益生无忧传染病医疗保险
			'001': {
				EPLBKS: true,
			}
		},
		'FXJYB': { //复星家医保重大疾病保险
			'001': {
				EPLBKS: true,
			}
		},
		'FXKLES': { //复星康乐一生（2021版）重大疾病保险
			'001': {
				EPLBKS: true,
			}
		},
		// 'FXLXYSYL': { //这款产品没有上
		// 	'001': {
		// 		EPLBKS: true,
		// 	}
		// }
	},
	'1AN': {
		'YAZYWYBZJH': true, //易安住院无忧保障计划
		'YAZHJTYWX': true,//  - 综合交通工具意外险
	},
	RHASSURANCE: {
		'RHBJLBDCJFZDJBBX': true,// - 瑞华-倍嘉乐保多次给付重大疾病保险
	},
	APIINS: {
		'YXFEJZHBZ': true, //亚太财险“永幸福”E家综合保障保险
	},
	HIINS: {
		'HIINSRZZBX': true,//现代财产保险（中国）认知症保险
	},
	axa: {
		'AXAZYSHBWZYYLBX': true,//【安盛天平】卓越守护百万住院医疗保险（高血压患者专款）
		'AXAZYWYBWFABX2020': true,//【安盛天平】卓越无忧百万防癌保险2020版
		'AXAZYSHBWZYYLBXTNB': true,//【安盛天平】卓越无忧百万住院医疗保险（糖尿病专款）
		'AXAZYSHBWYLBX': true,//【安盛天平】卓越无忧百万住院医疗保险
		'AXAGRZHZYYL': true,//盛世臻选医疗保险(互联网专属)
		'AXASHSGGRYW': true,//安盛天平守护时光个人意外伤害保险（互联网专属）
		'AXAZYBWGRYW': true,//“卓越百万”个人综合意外保险（互联网专属）
		'AXAZYSHBWZYYL2022PLUSHLW': true,//安盛天平卓越守护百万住院医疗2022升级版（互联网专属）
		'AXAZYXXYLBX2023HLW': true,//安盛天平卓越馨选医疗保险（2023版）（互联网专属）
		'AXAZYLXJHLW': true, //安盛天平卓越旅行家
		'AXAZXTXLXBXHLW': true, //安盛天平智行天下旅行保险（2018版）（互联网专属）
		'AXASHWLXPX': true, //“守护未来”安盛学生平安保险
	},
	HQ: {
		'HQYHJSZEZS': true,//横琴永恒金生增额终身寿险
		'HQCSYJZEZS': true,//横琴传世赢家增额终身寿险
		'HQYSDZDJBBX': true,//横琴优时代重大疾病保险
		'HQCSYHZEZS': true,//横琴传世壹號增额终身寿险
        'HQZXYSYLNJ': true,//横琴臻享一生养老年金保险
		'HQYABZSSX': true,//横琴优爱宝终身寿险
		'HQCSYHZXBZEZS': true,//横琴传世壹号尊享版终身寿险
	},
	HUAGUI: {
		HGDMDQSX2021: true, //华贵大麦2021定期寿险(免体检版)
		HGXAZSS: true, //华贵小爱终身寿险
		HGTMJ2021: true, //华贵大麦甜蜜家2021定期寿险
		HGDM2021TJB: true, //华贵大麦2021定期寿险体检版
		HGXATJB: true, //华贵小爱终身寿体检版
		HGQQNB: true //华贵大麦全能保险
	},
	KUNLUN: {
		'KLJKBDBB': true,//健康保（多倍版）重大疾病保险
		'KLJKBPHDBB': true,//健康保普惠多倍版重大疾病保险
		'KABJBBZJH': true,//康爱宝疾病保险
	},
	SHLIFE: {
		'SHRSYXZS': true,//上海人寿优选终身寿险
	},
	xint: {
		'XTRYRSSHYXBZDJBBX': true,//信泰如意人生守护（英雄版）重大疾病保险
		'XTRYZZSSX': true,//信泰如意尊终身寿险
		'XTRYZZSSX2': true,//信泰如意尊（2.0版）终身寿险 暂时先关闭
	},
	CHINALIFE: {
		TAXYLBX2020: true,//特安心医疗保险（2020版）
		AXG: true,//安心雇
		AXYSXB: true,//安心养舒心版
		BWAXL: true,//百万安心疗
		JLB: true,//家乐宝
		JYRQZHBX: true,//家用燃气综合保险
		XHZX: true,//熊孩子险
	},
	starrchina: {
		SDCQSBWYWBZJH: true,//史带永达理常青树百万意外保障计划
		SDCAZYJNLX: true,//史带永达理爱自由境内旅行保障计划3代
		SDCLNRYWBZ: true,//史带永达理老年人意外保障
	},
	hongk: {
		HKXGZX: true //弘康佑安意外保险
	},
	futurebaobei: {
		YCRYB: true //永诚任医保
	},
	AIXIN: {
		AXRSXXSNJBXWX: true,//爱心人寿心相随年金保险
		AXRSYLDYLNJBX: true,//爱心人寿养乐多养老年金保险
		AXRSYSHZEZSSX: true //爱心映山红增额终身寿
	},
	PAMHI: {
		PAESBCQGRZYYL: true, // 平安e生保长期个人住院医疗
	},
	zhongan: {
		ZXES2021: true //尊享e生2021版
	},
	dingcheng: {
		dcdingfeng1hao: true,//鼎诚鼎峰1号终身寿险
	},
	ZRHUIJIN:{
		GHYXBLQ:true,//国华悦行保两全保险
	},
	CLL:{
		LJZGTXXCS:true,//陆家嘴国泰鑫玺传世
	},
	CHIC: {
		NETM9848QR: true, //肺康宝·肺部恶性肿瘤医疗保险 
	}
} as object;

export const onGluttonHandled = (options: {
	vendorCode: string;
	productCode: string;
	productVersion: string;
}): boolean => {
	const { vendorCode, productCode, productVersion } = options;
	if (!vendorCode) {
		return false;
	}
	//@ts-ignore
	const vendorCodeValue = GluttonProducts[vendorCode] as any;
	if (Utils.isBoolean(vendorCodeValue)) {
		return vendorCodeValue;
	}
	if (!vendorCodeValue) {
		return false;
	}
	if (!productCode) {
		return false;
	}
	const productCodeValue = (vendorCodeValue || {})[productCode];
	if (Utils.isBoolean(productCodeValue)) {
		return productCodeValue;
	}
	if (!productCodeValue) {
		return false;
	}
	if (!productVersion) {
		return false;
	}
	const productVersionValue = (productCodeValue || {})[productVersion];
	if (Utils.isBoolean(productVersionValue)) {
		return productVersionValue;
	}
	if (!productVersionValue) {
		return false;
	}
	const { tenantCode } = Envs.findAccount();
	if (!tenantCode) {
		return false;
	}
	const tenantValue = (productVersionValue || {})[tenantCode];
	if (Utils.isBoolean(tenantValue)) {
		return tenantValue;
	}
	if (!tenantValue) {
		return false;
	}
	return false;
}
