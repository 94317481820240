import { AjaxHandler, AjaxHeaders, AjaxResponse } from '@my-types';
import { OssKitHelper as OssKit } from './oss-kit2';
import Consts from './consts';
import { Utils } from '.';

/**
 */
class OssJsonAjax {
	/**
	 * 从响应中读取headers
	 */
	private static buildResponseHeaders = (response: Response): AjaxHeaders => {
		const headers: AjaxHeaders = {};
		response.headers.forEach((value: string, key: string) => {
			headers[key] = value;
		});
		return headers;
	};
	/**
	 * 将响应构造成为标准响应格式
	 */
	private static buildResponseData = (response: Response, body: any): AjaxResponse => {
		return {
			headers: OssJsonAjax.buildResponseHeaders(response),
			body: body,
			status: response.status,
			statusText: response.statusText
		};
	};
	/**
	 * 处理响应数据
	 *
	 * @param {Response} response
	 * @param {string} responseType
	 * @param {function} successHandler 解析成功处理器
	 * @param {function} failHandler 解析失败处理器
	 */
	private static handleResponse = (
		response: Response,
		responseType: string | null,
		successHandler: AjaxHandler,
		failHandler: AjaxHandler
	): void => {
		const contentType = response.headers.get('content-type') || '';
		if (contentType.includes('json')) {
			responseType = 'json';
		} else if (contentType.includes('xml') || contentType.includes('text') || contentType.includes('html')) {
			responseType = 'text';
		}

		let extractor = null;
		switch (responseType) {
			case 'text':
				extractor = Response.prototype.text;
				break;
			case 'blob':
				extractor = Response.prototype.blob;
				break;
			default:
				// json
				extractor = Response.prototype.json;
				break;
		}
		extractor
			.call(response)
			.then(data => {
				//对象是string,转化成object对象
				if (Utils.isString(data)) {
					data = JSON.parse(data);
				}
				successHandler(OssJsonAjax.buildResponseData(response, data));
			})
			.catch(ex => {
				OssJsonAjax.rejectWithError(ex, failHandler);
			});
	};
	/**
	 * 模拟一个标准的错误返回, 状态码是0
	 */
	private static rejectWithError = (error: Error, reject: AjaxHandler): void => {
		reject({
			headers: {},
			body: {
				errors: [
					{
						code: Consts.FETCH_ERROR,
						description: `${error.name}: ${error.message}`
					}
				]
			},
			status: 0,
			statusText: 'Fetch error occurred.',
			// keep original error here
			error: error
		});
	};

	/**
	 * 模拟一个标准的错误返回, 状态码是200
	 */
	private static resolveWithOK = (response: Response, resolve: AjaxHandler): void => {
		resolve(Object.assign({
			headers: {},
			body: {

			},
			status: 200,
			statusText: 'Fetch ok occurred.',
		}));
	};

	put(ossKey: string, jsonData: any): Promise<AjaxResponse> {
		return new Promise(async (resolve, reject) => {
			try {
				const newBlob = new Blob([JSON.stringify(jsonData)], {
					type: 'application/json'
				});
				const response = await OssKit.getInstance().uploadFile(newBlob, ossKey);
				OssJsonAjax.resolveWithOK(response, resolve);
			} catch (e) {
				OssJsonAjax.rejectWithError(e as any, reject);
			}
		});
	}

	/**
	 * 接收的也是oss的json文件
	 * @param {string} url
	 * @param {*} data
	 * @param {*} options
	 */
	 get(ossKey: string): Promise<AjaxResponse> {
		return new Promise(async (resolve, reject) => {
			const ossUrl = await OssKit.getInstance().signatureUrl(ossKey, {
				expires: Consts.STS_SIGNATURE_EXPIRES,
			});
			fetch(ossUrl, {
				method: 'GET'
			}).then((response: Response) => {
				OssJsonAjax.handleResponse(response, 'json', resolve, reject);
			}).catch(async (e: Error) => {
				//1秒后重试
				const sleep = async (time: number): Promise<void> => {
					return new Promise((resolve) => setTimeout(resolve, time));
				}
				await sleep(1000);
				const ossUrlAgain = await OssKit.getInstance().signatureUrl(ossKey, {
					expires: Consts.STS_SIGNATURE_EXPIRES,
				});
				fetch(ossUrlAgain, {
					method: 'GET'
				}).then((response: Response) => {
					OssJsonAjax.handleResponse(response, 'json', resolve, reject);
				}).catch((e: Error) => {
					OssJsonAjax.rejectWithError(e, reject);
				});
				//OssJsonAjax.rejectWithError(e, reject);
			});

		});
	}

}

export default new OssJsonAjax();
