// import 'react-app-polyfill/stable';
import { React, ReactDOM } from '@common/3rd';
import App from './app';
// import registerServiceWorker from './registerServiceWorker';

/**
 * 定义全局对象
 */
declare global {
	interface Window {
		whiteScreen: EventListener[];
		__wxjs_environment?: string;
		__EBAO_JSSDK?: {
			callNative: (command: string) => void;
		};
		webkit?: {
			messageHandlers?: {
				__EBAO_JSSDK?: {
					postMessage: (command: string) => void;
				};
			};
		};
		gluttonRecorder: any;
	}
}

if (window.whiteScreen) {
	['touchstart', 'touchend'].forEach(name =>
		window.whiteScreen.forEach(func => window.removeEventListener(name, func))
	);
	delete window.whiteScreen;
}

// add polyfill
if (!Array.prototype.flat) {
	// eslint-disable-next-line
	Array.prototype.flat = function () {
		var depth = arguments[0];
		depth = depth === undefined ? 1 : Math.floor(depth);
		if (depth < 1) return Array.prototype.slice.call(this);
		return (function flat(arr, depth) {
			//@ts-ignore
			var len = arr.length >>> 0;
			var flattened = [] as any[];
			var i = 0;
			while (i < len) {
				if (i in arr) {
					//@ts-ignore
					var el = arr[i];
					//@ts-ignore
					if (Array.isArray(el) && depth > 0) flattened = flattened.concat(flat(el, depth - 1));
					else flattened.push(el);
				}
				i++;
			}
			return flattened;
		})(this, depth);
	};
}

console.time('Render whole application.');
ReactDOM.render(<App />, document.getElementById('root'), () => {
	console.timeEnd('Render whole application.');
});

if (process.env.REACT_APP_ENV_NAME !== 'PROD') {
	// 环境提示, 生产环境不出现
	(() => {
		const reminder: HTMLElement = document.createElement('div');
		reminder.innerText = `此为${process.env.REACT_APP_ENV_NAME}环境, 一切数据均仅为测试用途.`;

		const style = reminder.style;
		style.display = 'block';
		style.position = 'fixed';
		style.top = '0';
		style.left = '15vw';
		style.height = '20';
		style.lineHeight = '20px';
		style.width = '70vw';
		style.textAlign = 'center';
		style.fontSize = '12px';
		style.backgroundColor = '#ff2233';
		style.color = 'white';
		style.borderRadius = '0 0 20px 20px';
		style.opacity = '0.7';
		style.pointerEvents = 'none';
		style.zIndex = '75';
		document.body.appendChild(reminder);
	})();
}



// registerServiceWorker();
